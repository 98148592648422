<template>
  <BaseInput
    v-bind="props"
    :rules="[
      typoSuggestRule(),
      email(),
      maxLength(255),
      ...(props.rules ?? []),
    ]"
    v-on="
      reEmit(emit, ['update:loading', 'validationError', 'update:modelValue'])
    "
  >
    <template #input="{ id, describedBy, errorId, listeners, invalid }">
      <PInputText
        :id
        v-model="modelValue"
        :aria-describedby="describedBy"
        :aria-errormessage="errorId"
        :disabled
        fluid
        :invalid
        maxlength="255"
        :name
        :pt="assign({ root: { name } }, pt?.pcInput ?? {})"
        :required
        :size
        v-on="listeners"
        @focus="emit('focus', name)"
      />
    </template>
    <template #emailTypoSuggest>
      <PMessage
        :class="$style.suggestion"
        :pt="pt?.pcEmailTypoSuggest"
        severity="gray"
      >
        {{ tr("common_fe.fields.email.did_you_mean") }}
        <PButton
          action-type="quaternary"
          :class="$style.suggestionButton"
          size="small"
          text
          @click="emit('update:modelValue', suggestion!)"
        >
          {{ suggestion }}
        </PButton>
      </PMessage>
    </template>
    <template v-for="(_index, slotName) in $slots" #[slotName]="data">
      <slot :name="slotName" v-bind="data" />
    </template>
  </BaseInput>
</template>

<script setup lang="ts">
import type {
  InputTextPassThroughOptions,
  MessagePassThroughOptions,
} from "primevue";

import { useModel } from "@solvari/common-fe/composables";
import { reEmit } from "@solvari/common-fe/helpers";
import { email, maxLength } from "@solvari/common-fe/validation";
import { assign } from "radash";
import { toRef } from "vue";

import { type LocaleMorpheus, useI18nCommonFe } from "@solvari/translations";

import type {
  BaseInputEmits,
  BaseInputPassThroughOptions,
  BaseInputProps,
} from "@/molecules/baseInput.ts";

import { useEmailTypoCorrection } from "@/helpers/useEmailTypoCorrection.ts";
import BaseInput from "@/molecules/BaseInput.vue";
import { PButton, PInputText, PMessage } from "@/primeVueExports.ts";

const props = defineProps<
  BaseInputProps<string | null | undefined> & {
    pt?: BaseInputPassThroughOptions & {
      pcEmailTypoSuggest?: MessagePassThroughOptions;
      pcInput?: InputTextPassThroughOptions;
    };
    typoCorrection?: LocaleMorpheus;
  }
>();
const emit = defineEmits<
  BaseInputEmits &
    ((event: "focus", name: string) => void) &
    ((event: "update:modelValue", value: string | null | undefined) => void)
>();

const modelValue = useModel("modelValue", props, emit);

const { tr } = useI18nCommonFe();

const { typoSuggestRule, suggestion } = useEmailTypoCorrection(
  toRef(() => (props.typoCorrection ? props.modelValue : "")),
  toRef(() => props.typoCorrection ?? "nl-NL"),
);
</script>

<style module lang="postcss">
.suggestion {
  @apply mt-2;

  &Button {
    @apply ml-0;
  }
}
</style>
