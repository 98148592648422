<template>
  <SButtonIcon
    v-if="props.tooltip"
    v-bind="props"
    action-type="quaternary"
    icon="fa-solid fa-circle-info"
    size="inline"
    type="button"
  />
</template>

<script setup lang="ts">
import SButtonIcon from "@/lib/components/atoms/button/SButtonIcon.vue";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";

const props = defineProps(useTooltip.props);
</script>
