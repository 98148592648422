import { HTTPError, TimeoutError } from "ky";

import { getSentry } from "@/lib/integrations/sentry/client.ts";

function withKyErrors<Args extends unknown[], Return>(
  callback: (...args: Args) => Promise<Return>,
  sentry:
    | boolean
    | {
        default?: boolean;
        httpError?: boolean;
        timeoutError?: boolean;
      } = false,
) {
  const sentryConfig =
    typeof sentry === "boolean"
      ? { default: sentry, httpError: sentry, timeoutError: sentry }
      : {
          default: sentry.default ?? true,
          httpError: sentry.httpError ?? sentry.default ?? true,
          timeoutError: sentry.timeoutError ?? sentry.default ?? true,
        };

  return async (...args: Args) => {
    try {
      return await callback(...args);
    } catch (error) {
      const errorType = (() => {
        switch (true) {
          case error instanceof HTTPError:
            return "httpError";
          case error instanceof TimeoutError:
            return "timeoutError";
          default:
            return "default";
        }
      })();

      if (sentryConfig[errorType]) {
        getSentry().captureException(error);
      }

      return error as Error | HTTPError | TimeoutError;
    }
  };
}

export { withKyErrors };
