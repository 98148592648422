import type { DeepReadonly, MaybeRefOrGetter, Ref } from "vue";

import {
  autocompleteNlNlAddress,
  defineRule,
  getZipcodeCitySuggestions,
  onCreatedIfValue,
  zipcodeRule,
} from "@solvari/common-fe/validation";
import { computed, toValue } from "vue";

import type { LocaleIso } from "@solvari/translations";

function useLocaleSwitch(
  locale: Ref<LocaleIso | string | undefined>,
  availableLocales?: DeepReadonly<MaybeRefOrGetter<LocaleIso[]>>,
) {
  const alternateLocales = Object.freeze({
    "nl-NL": "nl-BE",
    "nl-BE": "nl-NL",
    "fr-BE": "fr-FR",
    "fr-FR": "fr-BE",
  } as const);

  function localeSupportsSwitch(
    locale: string | undefined,
  ): locale is keyof typeof alternateLocales {
    return !!locale && locale in alternateLocales;
  }

  const alternateLocale = computed(() => {
    return localeSupportsSwitch(locale.value)
      ? alternateLocales[locale.value]
      : null;
  });

  function switchLocale() {
    if (alternateLocale.value) {
      locale.value = alternateLocale.value;
    }
  }

  const localeSwitchRule = defineRule({
    name: "localeSwitch",
    component: "localeSwitch",
    validate: async (zipcode) => !(await shouldSwitchLocale(zipcode)),
    events: onCreatedIfValue(["blur"]),
    color: "primary",
    message: "",
  });

  async function shouldSwitchLocale(zipcode: unknown) {
    if (
      !localeSupportsSwitch(locale.value) ||
      !alternateLocale.value ||
      typeof zipcode !== "string"
    ) {
      return false;
    }

    if (
      !toValue(availableLocales)?.includes(alternateLocale.value) ||
      zipcodeRule(locale.value).validate(zipcode)
    ) {
      return false;
    }

    if (
      alternateLocale.value === "nl-BE" &&
      zipcodeRule("nl-BE").validate(zipcode)
    ) {
      await tryNlBESwitch(zipcode);
      return true;
    }

    if (
      alternateLocale.value === "nl-NL" &&
      zipcodeRule("nl-NL").validate(zipcode)
    ) {
      await tryNlNLSwitch(zipcode);
      return true;
    }

    if (
      alternateLocale.value === "fr-FR" &&
      zipcodeRule("fr-FR").validate(zipcode)
    ) {
      // No auto switching to fr-FR;
      return true;
    }

    if (
      alternateLocale.value === "fr-BE" &&
      zipcodeRule("fr-BE").validate(zipcode)
    ) {
      // No auto switching to fr-BE;
      return true;
    }

    // Invalid format
    return false;
  }

  async function tryNlBESwitch(zipcode: string) {
    const result = await getZipcodeCitySuggestions({
      city: "",
      locale: "nl-BE",
      zipcode,
    });

    const isExistingNlBE =
      !(result instanceof Error) &&
      result.some(
        ({ zipcode: zipcodeSuggestion }) => zipcodeSuggestion === zipcode,
      );

    if (isExistingNlBE) {
      switchLocale();
    }
  }

  async function tryNlNLSwitch(zipcode: string) {
    if (await autocompleteNlNlAddress({ zipcode })) {
      switchLocale();
    }
  }

  return { localeSwitchRule, switchLocale };
}

export { useLocaleSwitch };
