<template>
  <div class="s-address--mapbox">
    <div
      v-if="showAutocomplete"
      class="s-address--mapbox-container"
      :class="{ 's-address--mapbox-container--split': manualHouseNumber }"
    >
      <SAutoComplete
        v-model="searchString"
        class="s-address--mapbox__search"
        :empty-search-message="tr('address_input.autocomplete.no_results')"
        :label="tr('address_input.autocomplete.label')"
        :loading="searchLoading"
        :name="tr('address_input.autocomplete.label')"
        :placeholder="tr('address_input.autocomplete.placeholder')"
        :rules="[houseNumberExistsRule()]"
        :suggestions="suggestions"
        v-on="listeners"
      >
        <template #footer>
          <div class="s-address--mapbox__search__footer">
            <PButton
              :label="tr('address_input.switch_to_manual')"
              size="small"
              variant="link"
              @click="emit('showManual')"
            />
          </div>
        </template>
      </SAutoComplete>
      <STextInput
        v-if="manualHouseNumber"
        v-model="houseNumber"
        class="s-address--mapbox__house-number"
        :label="tr('address_input.house_number.label')"
        :maxlength="255"
        name="houseNumber"
        :placeholder="tr('address_input.house_number.placeholder')"
        required
        :size
      />
    </div>
    <PMessage
      v-if="street && city"
      class="s-address--mapbox__result"
      color="gray"
      icon="far fa-house"
      :size="size"
    >
      {{ street }} {{ houseNumber }}, {{ zipcode }} {{ city }}
      <PButton
        class="s-address--mapbox__result__button"
        size="small"
        text
        @click="openAutoComplete"
      >
        {{ tr("address_input.change_button") }}
      </PButton>
    </PMessage>
  </div>
</template>

<script setup lang="ts">
import { defineRule } from "@solvari/common-fe/validation";
import { defineModel, defineProps, onBeforeMount, ref } from "vue";

import { type LocaleIso, useI18nAddressInput } from "@solvari/translations";

import SAutoComplete from "@/molecules/SAutoComplete.vue";
import STextInput from "@/molecules/STextInput.vue";
import {
  getAddressSuggestions,
  type MapBoxAutocompleteItem,
} from "@/organisms/address/mapbox.api";
import { PButton, PMessage } from "@/primeVueExports.ts";

const { tr } = useI18nAddressInput();

const zipcode = defineModel<string | null>("zipcode", { required: true });
const houseNumber = defineModel<string | null>("houseNumber", {
  required: true,
});
const city = defineModel<string | null>("city", { required: true });
const street = defineModel<string | null>("street", { required: true });

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  locale: "da-DK" | "sv-SE";
  size?: "large" | "small";
}>();

const emit = defineEmits<(event: "showManual") => void>();

const showAutocomplete = ref(false);
const searchString = ref("");
const suggestions = ref<MapBoxAutocompleteItem[]>([]);
const searchLoading = ref(false);
const manualHouseNumber = ref(false);

// 19-03-2025
// we are not going to provide a sessionID (required by mapbox)
// from the backed, payments wont be split per user
// and that makes this component to hard to share through the app.
const sessionId = "4jawgkiDXVtgidD26lxBY";

const openAutoComplete = () => {
  searchString.value = "";
  showAutocomplete.value = true;
};

const search = async ({ query }: { query: string }) => {
  searchLoading.value = true;

  const result = await getAddressSuggestions({
    locale: props.locale,
    query,
    sessionId,
  });

  if (result instanceof Error) {
    emit("showManual");
    return;
  }

  suggestions.value = result;

  searchLoading.value = false;
};

const optionSelected = ({ value }: { value: MapBoxAutocompleteItem }) => {
  street.value = value.street;
  city.value = value.city;
  zipcode.value = value.zipcode;
  houseNumber.value = value.houseNumber;

  if (!value.houseNumber) {
    manualHouseNumber.value = true;
  } else {
    manualHouseNumber.value = false;
    showAutocomplete.value = false;
    searchString.value = "";
  }
};

const houseNumberExistsRule = defineRule({
  name: "exists",
  validate: (value: unknown) => {
    return !(
      typeof value !== "string" &&
      !(value as MapBoxAutocompleteItem).houseNumber
    );
  },
  events: ["hide"],
  color: "warning",
  blocking: false,
  message: () =>
    houseNumber.value
      ? " "
      : tr("address_input.house_number.validation.exists"),
});

const listeners = {
  complete: search,
  optionSelect: optionSelected,
};

onBeforeMount(() => {
  if (!street.value && !houseNumber.value) {
    showAutocomplete.value = true;
  }
});
</script>

<style lang="postcss">
.s-address--mapbox {
  @apply flex flex-col gap-2;

  &__search {
    &__footer {
      @apply flex justify-center;
    }
  }

  &-container {
    @apply grid gap-2;

    &--split {
      grid-template-columns: 1fr 0.5fr;
    }
  }
}
</style>
