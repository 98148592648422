import { computed, toRef } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { size } from "@/lib/components/logic/atoms/input/props";
import { propsDefinition } from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  disabled: { type: Boolean, default: false },
  size,
  prefixIcon: {
    type: String,
    required: false,
  },
  suffixIcon: {
    type: String,
    required: false,
  },
});

const props = scoped;

type UseInputTextAtomProps = DefineProps<typeof props>;

function use(props: UseInputTextAtomProps) {
  const hasPrefixIcon = computed(() => !!props.prefixIcon);
  const hasSuffixIcon = computed(() => !!props.suffixIcon);

  return {
    hasPrefixIcon,
    hasSuffixIcon,
    size: toRef(() => props.size),
    disabled: toRef(() => props.disabled),
  };
}

export type { UseInputTextAtomProps };
export { props, scoped, use };
