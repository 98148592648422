<template>
  <div
    class="s-address--nl-nl"
    :class="{
      's-address--nl-nl--manual': showManual,
      's-address--nl-nl--autocompleted': showAutocompleteResults,
      [`s-address--nl-nl--size-${size}`]: !!size,
    }"
  >
    <STextInput
      v-model="zipcode"
      autocomplete="postal-code"
      class="s-address--nl-nl__zipcode"
      :label="tr('address_input.zipcode.label')"
      :maxlength="7"
      name="zipcode"
      required
      :rules="[
        required(undefined, {
          message: () => tr('address_input.zipcode.validation.required'),
        }),
        localeSwitchRule(),
        zipcodeRule('nl-NL'),
        zipcodeExistsRule('nl-NL'),
      ]"
      show-required-type="none"
      :size
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
      @blur="() => attemptAutocomplete()"
    >
      <template #localeSwitch>
        <PMessage
          class="s-error-atom s-address--nl-nl__locale-switch"
          icon="far fa-circle-exclamation"
          :size="size"
        >
          {{ tr("address_input.locale_warning.text") }} <br />
          <PButton
            icon="far fa-chevron-right"
            icon-pos="right"
            :label="tr('address_input.locale_warning.button')"
            link
            size="small"
            text
            @click="switchLocale"
          />
        </PMessage>
      </template>
    </STextInput>

    <SAutoComplete
      v-model="houseNumber"
      autocomplete="address-line2"
      class="s-address--nl-nl__house-number"
      :label="tr('address_input.house_number.label')"
      :loading="houseNumberSuggestionsFetching"
      name="houseNumber"
      option-label=""
      :placeholder="tr('address_input.house_number.placeholder')"
      :rules="[
        required(undefined, {
          message: () => tr('address_input.house_number.validation.required'),
        }),
        houseNumberExistsRule(),
      ]"
      :size
      :suggestions="houseNumberSuggestions"
      :validation-events="onCreatedIfValue(eager)"
      :validation-trigger="houseNumberValidationTrigger"
      v-on="listeners"
      @focus="emit('focus', 'houseNumber')"
    />

    <PMessage
      v-if="showAutocompleteResults"
      class="s-address--nl-nl__autocomplete"
      color="gray"
      icon="far fa-house"
      :size="size"
    >
      {{ street }}, {{ city }}
      <PButton
        class="s-address--nl-nl__autocomplete__button"
        link
        size="small"
        text
        @click="setManual"
      >
        {{ tr("address_input.change_button") }}
      </PButton>
    </PMessage>

    <template v-if="showManual">
      <SAutoComplete
        v-model="city"
        autocomplete="address-level2"
        class="s-address--nl-nl__city"
        :label="tr('address_input.city.label')"
        :loading="citySuggestionsFetching"
        name="city"
        option-label=""
        :rules="[
          required(undefined, {
            message: () => tr('address_input.city.validation.required'),
          }),
          cityExistsRule(),
        ]"
        :size
        :suggestions="citySuggestions"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
        @focus="emit('focus', 'city')"
      />

      <SAutoComplete
        v-model="street"
        autocomplete="address-line1"
        class="s-address--nl-nl__street"
        :label="tr('address_input.street.label')"
        :loading="streetSuggestionsFetching"
        name="street"
        option-label=""
        :placeholder="tr('address_input.street.placeholder')"
        :rules="[
          required(undefined, {
            message: () => tr('address_input.street.validation.required'),
          }),
          streetExistsRule(),
        ]"
        :size
        :suggestions="streetSuggestions"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
        @focus="emit('focus', 'street')"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ValidateEvent } from "@solvari/common-fe/validation";

import { reEmit, useModel } from "@solvari/common-fe/composables";
import { alphabeticCompare } from "@solvari/common-fe/helpers";
import {
  autocompleteNlNlAddress,
  defineRule,
  eager,
  getCitySuggestions,
  getHouseNumberSuggestions,
  getStreetSuggestions,
  onCreatedIfValue,
  required,
  zipcodeExistsRule,
  zipcodeRule,
} from "@solvari/common-fe/validation";
import { computedAsync, watchImmediate } from "@vueuse/core";
import { isString } from "radash";
import { ref } from "vue";

import { type LocaleIso, useI18nAddressInput } from "@solvari/translations";
import { asyncThrottle } from "@solvari/utils";

import SAutoComplete from "@/molecules/SAutoComplete.vue";
import STextInput from "@/molecules/STextInput.vue";
import { PButton, PMessage } from "@/primeVueExports.ts";

import { useLocaleSwitch } from "./useLocaleSwitch";

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  locale: "nl-NL";
  size?: "large" | "small";
}>();

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const { tr } = useI18nAddressInput();
const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcode = defineModel<string | null>("zipcode", { required: true });
const houseNumber = defineModel<string | null>("houseNumber", {
  required: true,
});
const city = defineModel<string | null>("city", { required: true });
const street = defineModel<string | null>("street", { required: true });

const showAutocompleteResults = ref(false);
const showManual = ref(false);

function setManual() {
  showManual.value = true;
  showAutocompleteResults.value = false;
}
const autocomplete = asyncThrottle(autocompleteNlNlAddress);

async function attemptAutocomplete() {
  if (!zipcode.value || !zipcodeRule("nl-NL").validate(zipcode.value)) {
    return;
  }
  const result = await autocomplete({
    zipcode: zipcode.value.replace(" ", "").toUpperCase(),
  });

  if (result instanceof Error || result === null) {
    setManual();
    return;
  }

  showManual.value = false;
  showAutocompleteResults.value = true;
  zipcode.value = result.zipcode;
  street.value = result.street;
  city.value = result.city;
}
void attemptAutocomplete();

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);

/*
  houseNumber
 */
const suggestHouseNumber = asyncThrottle(getHouseNumberSuggestions);
const houseNumberSuggestionsFetching = ref(false);
const houseNumberSuggestions = computedAsync(
  async () => {
    const result = await suggestHouseNumber({
      locale: "nl-NL",
      houseNumber: houseNumber.value,
      zipcode: zipcode.value,
    });
    return result instanceof Error ? [] : result;
  },
  [],
  { lazy: true, evaluating: houseNumberSuggestionsFetching },
);

const houseNumberExistsRule = defineRule({
  name: "exists",
  validate: async (houseNumber: unknown) => {
    if (!isString(houseNumber) || !houseNumber) {
      return false;
    }
    const result = await suggestHouseNumber({
      locale: "nl-NL",
      houseNumber,
      zipcode: zipcode.value,
    });
    return !(result instanceof Error) && result.includes(houseNumber);
  },
  events: ["blur", "update:zipcode"],
  color: "warning",
  blocking: false,
  message: () => tr("address_input.house_number.validation.exists"),
});

function houseNumberValidationTrigger(validateEvent: ValidateEvent) {
  watchImmediate(zipcode, () => {
    if (houseNumber.value) {
      void validateEvent("update:zipcode");
    }
  });
}

/*
  City
 */
const suggestCity = asyncThrottle(getCitySuggestions);

const citySuggestionsFetching = ref(false);
const citySuggestions = computedAsync(
  async () => {
    if (!showManual.value) {
      return [];
    }
    const result = await suggestCity({ locale: "nl-NL", city: city.value });
    return result instanceof Error ? [] : result;
  },
  [],
  { lazy: true, evaluating: citySuggestionsFetching },
);

const cityExistsRule = defineRule({
  name: "exists",
  validate: async (city) => {
    if (typeof city !== "string") {
      return false;
    }
    const result = await suggestCity({ locale: "nl-NL", city });
    return (
      !(result instanceof Error) &&
      result.some((suggestion) => alphabeticCompare(suggestion, city))
    );
  },
  events: onCreatedIfValue(["blur"], zipcode.value && city.value),
  color: "warning",
  blocking: false,
  message: () => tr("address_input.city.validation.exists"),
});

/*
  Street
 */
const suggestStreet = asyncThrottle(getStreetSuggestions);

const streetSuggestionsFetching = ref(false);
const streetSuggestions = computedAsync(
  async () => {
    if (!showManual.value) {
      return [];
    }
    const result = await suggestStreet({
      locale: "nl-NL",
      city: city.value,
      street: street.value,
    });
    return result instanceof Error ? [] : result;
  },
  [],
  { lazy: true, evaluating: streetSuggestionsFetching },
);

const streetExistsRule = defineRule({
  name: "exists",
  validate: async (street: unknown) => {
    if (typeof street !== "string") {
      return false;
    }
    const result = await suggestStreet({
      locale: "nl-NL",
      city: city.value,
      street,
    });
    return (
      !(result instanceof Error) &&
      result.some((suggestion) => alphabeticCompare(suggestion, street))
    );
  },
  events: onCreatedIfValue(["blur"], zipcode.value && street.value),
  color: "warning",
  blocking: false,
  message: () => tr("address_input.street.validation.exists"),
});
</script>

<style lang="postcss">
.s-address--nl-nl {
  @apply grid gap-4;
  grid-template-columns: 2fr minmax(min-content, 1fr);
  grid-template-areas: "zipcode house-number";

  &--autocompleted {
    grid-template-areas:
      "zipcode house-number"
      "autocomplete autocomplete";
  }

  &--manual {
    grid-template-areas:
      "zipcode house-number"
      "city ."
      "street .";
  }

  &--size {
    &-small {
      @apply gap-3;
    }

    &-large {
      @apply gap-5;
    }
  }

  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }

  &__zipcode {
    grid-area: zipcode;
  }

  &__city {
    grid-area: city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }

  &__autocomplete {
    @apply justify-self-start;
    grid-area: autocomplete;

    .s-button {
      @apply ml-2;
    }
  }
}
</style>
