import type { MaybeRefOrGetter, Ref } from "vue";

import { autoUpdate, flip, offset, useFloating } from "@floating-ui/vue";
import {
  onClickOutside,
  toReactive,
  useFocusWithin,
  useToString,
} from "@vueuse/core";
import { nanoid } from "nanoid";
import { omit } from "radash";
import {
  computed,
  reactive,
  ref,
  toRef,
  toValue,
  watch,
  watchEffect,
} from "vue";

import { useI18nCommonFe } from "@solvari/translations";

import type { PrimitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";
import type { UseInputIconAtomEmit } from "@/lib/components/logic/atoms/input/useInputIconAtom";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { OptionValue } from "@/lib/composables/useOptionsStore/useOptionsStore";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import { primitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";
import * as useInputIconAtom from "@/lib/components/logic/atoms/input/useInputIconAtom";
import * as useInputTextAtom from "@/lib/components/logic/atoms/input/useInputTextAtom";
import * as useInputWrapperAtom from "@/lib/components/logic/atoms/input/useInputWrapperAtom";
import * as useDescription from "@/lib/components/logic/atoms/useDescription";
import * as useLabel from "@/lib/components/logic/atoms/useLabel";
import * as useSubtext from "@/lib/components/logic/atoms/useSubtext";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";
import {
  useDescribedBy,
  useElementSize,
  useIsOpen,
  useModel,
  useTemplateRef,
} from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useLabelFallback } from "@/lib/composables/useLabelFallback.ts";
import { useActiveItem } from "@/lib/composables/useOptionsStore/useActiveItem";
import {
  useOptionsStore,
  useOptionsStoreScoped,
} from "@/lib/composables/useOptionsStore/useOptionsStore";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const useSelectListboxAlignmentProps = propsDefinition({
  alignmentOffset: { type: Number, default: 4 },
  alignmentPadding: { type: Number, default: 12 },
  alignmentOverflow: { type: Number, default: 0 },
});

const props = propsDefinition({
  ...useLabel.scoped,
  ...useTooltip.scoped,
  ...useDescription.scoped,
  ...useInputIconAtom.scoped,
  ...useInputTextAtom.scoped,
  ...useSubtext.scoped,
  ...useValidationProviderScoped(),
  ...useOptionsStoreScoped,
  name: { type: String, required: true },
  placeholder: { type: String, required: false },
  multiple: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  modelValue: primitiveOrArrayValue,
  suffixIcon: {
    type: String,
    default: () => "fa-regular fa-chevron-down",
  },
  openOnFocus: { type: Boolean, default: false },
  ...useSelectListboxAlignmentProps,
});

const emits = emitsDefinition([
  "update:modelValue",
  "update:loading",
  "focus",
  "blur",
  ...useInputIconAtom.emits,
  ...useValidationProviderEmits,
]);

type UseSelectProps = DefineProps<typeof props>;
type UseSelectEmit = UseInputIconAtomEmit &
  UseValidationProviderEmits & {
    (event: "blur" | "focus", value: string): void;
    (event: "update:loading", value: boolean): void;
    (event: "update:modelValue", value: PrimitiveOrArrayValue): void;
  };

function useSelectListboxAlignment(
  comboboxEl: Ref<HTMLElement | null>,
  listboxEl: Ref<HTMLElement | null>,
  alignmentOffset: MaybeRefOrGetter<number>,
  alignmentPadding: MaybeRefOrGetter<number>,
  alignmentOverflow: MaybeRefOrGetter<number>,
) {
  const { floatingStyles } = useFloating(comboboxEl, listboxEl, {
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    middleware: computed(() => {
      return [
        offset(toValue(alignmentOffset)),
        flip({ padding: toValue(alignmentPadding) }),
      ];
    }),
  });
  const { width } = useElementSize(comboboxEl);
  watchEffect(() => {
    if (!listboxEl.value) {
      return;
    }
    Object.assign(listboxEl.value.style, {
      ...floatingStyles.value,
      width: `${width.value + toValue(alignmentOverflow) * 2}px`,
      marginLeft: `-${toValue(alignmentOverflow)}px`,
    });
  });
}

function useSelectPlaceholder(
  placeholder: Readonly<Ref<string | null | undefined>>,
) {
  const { tr } = useI18nCommonFe();

  return computed(
    () =>
      placeholder.value ?? tr("common_fe.fields.default.select.placeholder"),
  );
}

function use(props: UseSelectProps, emit: UseSelectEmit) {
  const modelValue = useModel("modelValue", props, emit, { local: true });

  const labelId = ref(nanoid(10));
  const loading = useModel("loading", props, emit, { local: true });

  const { isOpen, open, close, toggleOpen } = useIsOpen(
    { onOpen, onClose },
    { openable: () => !props.disabled },
  );

  // Translations
  const { label, errorLabel } = useLabelFallback(
    toRef(() => props.name),
    reactivePick(props, ["label", "errorLabel"]),
  );

  const placeholder = useSelectPlaceholder(toRef(() => props.placeholder));

  // Ids
  const { describedBy, ids } = useDescribedBy(
    reactivePick(props, ["tooltip", "description", "subtext"]),
  );
  const listboxId = ref(nanoid(10));

  // Refs
  const { el: containerEl, ref: containerRef } = useTemplateRef();
  const { el: comboboxEl, ref: comboboxRef } = useTemplateRef();
  const { el: listboxEl, ref: listboxRef } = useTemplateRef();

  useSelectListboxAlignment(
    comboboxEl,
    listboxEl,
    () => props.alignmentOffset,
    () => props.alignmentPadding,
    () => props.alignmentOverflow,
  );

  const {
    validationListeners,
    error,
    errorComponent,
    errorProps,
    inputProps,
    validating,
  } = useValidationProvider(
    modelValue,
    mergeReactive(props, { loading, errorLabel }),
    emit,
  );

  const { focused: isFocused } = useFocusWithin(containerEl);
  watch(isFocused, (isFocused) => {
    if (isFocused) {
      void validationListeners.value.focus?.();
      emit("focus", props.name);
      if (props.openOnFocus) {
        open();
      }
    } else {
      void validationListeners.value.blur?.();
      emit("blur", props.name);
    }
  });

  const {
    allItems,
    items,
    findItemIndex,
    tryToggleValue,
    setChecked,
    setIsOpen,
    activeItemValue,
    removeValue,
  } = useOptionsStore(modelValue, props);

  const multiValueShown = computed(() => {
    return !!allItems.value.length && props.multiple;
  });

  const {
    activeItem,
    activeDescendantId,
    setActiveItemByValue,
    setActiveItemByIndex,
    setNextActiveItem,
    setPreviousActiveItem,
    setFirstVisibleActive,
  } = useActiveItem(modelValue, activeItemValue, items, findItemIndex);

  function selectActiveItem() {
    if (activeItem.value) {
      tryToggleValue(activeItem.value.value);
    }
    itemSelected();
  }

  onClickOutside(containerEl, close);

  function onContainerClick(event: PointerEvent) {
    event.preventDefault();
    if (props.disabled) {
      return;
    }
    comboboxEl.value?.focus();
  }

  function onOpen() {
    setFirstVisibleActive();
  }

  function onClose() {
    activeItemValue.value = null;
  }

  function onListboxClick(value: OptionValue) {
    setActiveItemByValue(value);
  }

  function onListboxInput(
    checked: boolean,
    value: OptionValue | OptionValue[],
  ) {
    setChecked(checked, value);
    itemSelected();
  }

  function itemSelected() {
    if (!props.multiple) {
      close();
    }
  }

  function onComboboxKeydown(event: KeyboardEvent) {
    if (isOpen.value) {
      onKeydownOpen(event);
    } else {
      onKeydownClosed(event);
    }
  }

  function onKeydownClosed(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Enter":
      case " ": {
        open();
        break;
      }
      case "Home": {
        open();
        setActiveItemByIndex(0);
        break;
      }
      case "End": {
        open();
        setActiveItemByIndex(items.value.length - 1);
        break;
      }
    }
  }

  function onKeydownOpen(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowDown": {
        setNextActiveItem();
        break;
      }
      case "ArrowUp": {
        setPreviousActiveItem();
        break;
      }
      case "ArrowRight": {
        if (activeItem.value?.children?.length && !activeItem.value.isOpen) {
          setIsOpen(activeItem.value.value, true);
          setNextActiveItem();
        }
        break;
      }
      case "ArrowLeft": {
        if (activeItem.value?.children?.length && activeItem.value.isOpen) {
          setIsOpen(activeItem.value.value, false);
        }
        break;
      }
      case "Home": {
        setActiveItemByIndex(0);
        break;
      }
      case "End": {
        setActiveItemByIndex(items.value.length - 1);
        break;
      }
      case "PageDown": {
        setNextActiveItem(10);
        break;
      }
      case "PageUp": {
        setPreviousActiveItem(10);
        break;
      }
      case "Enter":
      case " ": {
        selectActiveItem();
        break;
      }
      case "Escape":
      case "Tab": {
        close();
        break;
      }
    }
  }

  /*
    Components
   */

  const container = { ref: containerRef, on: { mousedown: onContainerClick } };

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabel.scoped), { labelId, label }),
  };

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltip.scoped), {
      tooltipId: toRef(() => ids.tooltip),
    }),
  };

  const descriptionAtom = {
    if: computed(() => !!props.description),
    props: mergeReactive(pickProps(props, useDescription.scoped), {
      descriptionId: toRef(() => ids.description),
    }),
  };

  const inputIconAtom = {
    props: mergeReactive(
      pickProps(props, useInputIconAtom.scoped),
      toReactive(inputProps),
      { loading: toRef(() => loading.value || validating.value) },
    ),
    on: reEmit(emit, useInputIconAtom.emits),
  };

  const inputTextAtom = { props: pickProps(props, useInputTextAtom.scoped) };

  const comboboxAtom = {
    ref: comboboxRef,
    props: mergeReactive(
      pickProps(props, useInputWrapperAtom.scoped),
      {
        id: nanoid(10),
        tabindex: toRef(() => (props.disabled ? -1 : 0)),
        "aria-labelledby": labelId,
        "aria-expanded": useToString(isOpen) as Ref<"false" | "true">,
        "aria-activedescendant": activeDescendantId,
        "aria-describedby": describedBy,
        "aria-controls": listboxId,
        "aria-disabled": toRef(() => props.disabled),
        role: "combobox",
        name: toRef(() => props.name),
        isOpen,
      },
      toReactive(inputProps),
    ),
    on: mergeListeners(
      {
        click: toggleOpen,
        keydown: onComboboxKeydown,
      },
      omit(toReactive(validationListeners), ["focus", "blur"]),
    ),
  };

  const comboboxText = computed(() => {
    return allItems.value[0]?.label ?? placeholder.value;
  });

  const listboxAtom = {
    ref: listboxRef,
    props: reactive({
      listboxId,
      items,
      size: toRef(() => props.size),
      multiple: toRef(() => props.multiple),
    }),
    on: {
      click: onListboxClick,
      "update:modelValue": onListboxInput,
      "update:isOpen": setIsOpen,
    },
  };

  const subtextAtom = {
    if: computed(() => !!props.subtext),
    props: mergeReactive(pickProps(props, useSubtext.scoped), {
      subtextId: toRef(() => ids.subtext),
    }),
  };

  const multiValueDisplayAtom = reactive({
    if: multiValueShown,
    props: { items: allItems },
    on: { removeValue },
  });

  return {
    container,
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    inputIconAtom,
    inputTextAtom,
    subtextAtom,
    comboboxAtom,
    listboxAtom,
    isFocused,
    isOpen,
    multiValueDisplayAtom,
    comboboxText,
    error,
    errorComponent,
    errorProps,
  };
}

export type { UseSelectEmit, UseSelectProps };

export {
  emits,
  props,
  use,
  useSelectListboxAlignment,
  useSelectListboxAlignmentProps,
  useSelectPlaceholder,
};
