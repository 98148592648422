<template>
  <SAddressZipcodeCity
    v-if="locale === 'nl-BE' || locale === 'fr-BE' || locale === 'da-DK'"
    v-bind="props"
    :locale
    v-on="addressOn"
  />

  <SAddressNlNl
    v-else-if="locale === 'nl-NL'"
    v-bind="props"
    :locale
    v-on="addressOn"
  />

  <SAddressManual v-else v-bind="props" :locale v-on="addressOn" />
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";

import type { LocaleIso } from "@solvari/translations";

import SAddressManual from "./SAddressManual.vue";
import SAddressNlNl from "./SAddressNlNl.vue";
import SAddressZipcodeCity from "./SAddressZipcodeCity.vue";

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  city: string | null;
  houseNumber: string | null;
  locale?: LocaleIso | string;
  size?: "large" | "small";
  street: string | null;
  zipcode: string | null;
}>();

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:city", city: string): void;
  (event: "update:houseNumber", houseNumber: string): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "update:street", street: string): void;
  (event: "update:zipcode", zipcode: string): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const addressOn = reEmit(emit, [
  "update:locale",
  "update:zipcode",
  "update:houseNumber",
  "update:street",
  "update:city",
  "focus",
  "blur",
  "validationError",
]);
</script>
