<template>
  <p class="s-form__legal-disclaimer" v-html="tr('forms.legal.disclaimer')" />
</template>

<script setup lang="ts">
import { useI18nForms } from "@/plugins/i18n.ts";

const { tr } = useI18nForms();
</script>

<style lang="postcss">
.s-form__legal-disclaimer {
  @apply mb-8 text-sm;

  a {
    @apply text-primary-700;
  }
}
</style>
