import { computed } from "vue";

import type { UseButtonEmit } from "@/lib/components/logic/atoms/useButton";
import type { DefineProps } from "@/lib/composables/componentComposable";

import * as useButton from "@/lib/components/logic/atoms/useButton";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

const props = propsDefinition({
  icon: { type: String, required: true },
  ...useButton.props,
});

const emits = emitsDefinition(useButton.emits);

type UseButtonIconProps = DefineProps<typeof props>;
type UseButtonIconEmit = UseButtonEmit;

function use(props: UseButtonIconProps, emit: UseButtonIconEmit) {
  const internalIcon = computed(() => {
    return props.icon;
  });

  const { tag, buttonProps, actionType, click, size, color, disabled } =
    useButton.use(props, emit);

  return {
    icon: internalIcon,
    tag,
    buttonProps,
    actionType,
    click,
    size,
    color,
    disabled,
  };
}

export type { UseButtonIconEmit, UseButtonIconProps };
export { emits, props, use };
