<template>
  <div
    class="s-upload"
    :class="{
      's-upload--dragover': isDraggingOver,
      's-upload--invalid': invalid,
      's-upload--disabled': disabled,
    }"
  >
    <i class="s-upload__icon" :class="icon"></i>
    <div :id="placeholderId" class="s-upload__placeholder">
      {{ placeholder }}
    </div>
    <div :id="hintId" class="s-upload__hint">
      {{ hint }}
    </div>
    <input class="s-upload__input" v-bind="input.props" v-on="input.on" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { UseUploadEmit } from "@/lib/components/logic/atoms/useUpload";

import * as useUpload from "@/lib/components/logic/atoms/useUpload";

const props = defineProps(useUpload.props);
const emit = defineEmits<UseUploadEmit>();

const {
  isDraggingOver,
  invalid,
  disabled,
  input,
  placeholder,
  placeholderId,
  hint,
  hintId,
} = useUpload.use(props, emit);

const icon = computed(() => {
  if (isDraggingOver.value) {
    return "fa-light fa-cloud-plus";
  }
  if (invalid.value) {
    return "fa-light fa-cloud-exclamation";
  }
  return "fa-light fa-cloud-arrow-up";
});
</script>

<style lang="postcss">
.s-upload {
  @apply relative mb-3 flex cursor-pointer flex-col items-center rounded border border-dashed border-gray-200 bg-white p-4 transition-colors;

  &:hover {
    @apply bg-gray-50;
  }

  &--invalid {
    @apply border-solid border-danger-500;

    .s-upload__icon {
      @apply text-danger-500;
    }
  }

  &--disabled,
  &--disabled:hover {
    @apply border-solid border-gray-200 bg-gray-100;

    .s-upload {
      &__icon {
        @apply text-gray-400;
      }

      &__placeholder {
        @apply text-gray-600;
      }

      &__input {
        @apply cursor-default;
      }
    }
  }

  &--dragover {
    @apply border-dashed border-primary-700 bg-primary-50;

    .s-upload__icon {
      @apply text-primary-500;
    }
  }

  &__icon {
    @apply mb-2 h-14 w-14 text-primary-500;
  }

  &__placeholder {
    @apply mb-1 font-medium text-gray-800;
  }

  &__hint {
    @apply text-sm text-gray-400;
  }

  &__input {
    @apply absolute left-0 top-0 h-full w-full cursor-pointer opacity-0;
  }
}
</style>
