import { toReactive } from "@vueuse/core";
import { omit } from "radash";
import { computed, toRef } from "vue";

import type { UseCheckboxRadioEmit } from "@/lib/components/logic/atoms/useCheckboxRadio";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import * as useCheckboxRadio from "@/lib/components/logic/atoms/useCheckboxRadio";
import * as useSubtext from "@/lib/components/logic/atoms/useSubtext";
import { useDescribedBy } from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useLabelFallback } from "@/lib/composables/useLabelFallback.ts";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useSubtext.scoped,
  ...useValidationProviderScoped<boolean>(),
  ...omit(useCheckboxRadio.props, ["value"]),
  modelValue: { type: Boolean, default: false },
});

const emits = emitsDefinition([
  ...useCheckboxRadio.emits,
  ...useValidationProviderEmits,
]);

type UseCheckboxProps = DefineProps<typeof props>;
type UseCheckboxEmit = UseCheckboxRadioEmit & UseValidationProviderEmits;

function use(props: UseCheckboxProps, emit: UseCheckboxEmit) {
  const { label } = useLabelFallback(
    toRef(() => props.name),
    reactivePick(props, ["label"]),
  );

  const { describedBy, ids } = useDescribedBy(reactivePick(props, ["subtext"]));
  const { error, errorComponent, errorProps, validationListeners } =
    useValidationProvider(() => props.modelValue, props, emit);

  const checkboxRadio = {
    props: mergeReactive(
      pickProps(props, omit(useCheckboxRadio.props, ["value"])),
      {
        describedBy,
        value: toRef(() => props.name),
        type: "checkbox" as const,
        label,
      },
    ),
    on: mergeListeners(
      reEmit(emit, useCheckboxRadio.emits),
      toReactive(validationListeners),
    ),
  };

  const subtextAtom = {
    if: computed(() => !!props.subtext),
    props: mergeReactive(pickProps(props, useSubtext.scoped), {
      subtextId: toRef(() => ids.subtext),
    }),
  };

  return {
    checked: toRef(() => props.modelValue),
    checkboxRadio,
    error,
    errorComponent,
    errorProps,
    subtextAtom,
  };
}

export type { UseCheckboxEmit, UseCheckboxProps };
export { emits, props, use };
