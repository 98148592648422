import ky from "ky";

import { defineI18n, localeToMorpheus } from "@solvari/translations";
import type { FormsTranslations, LocaleIso } from "@solvari/translations";
import { getEnv, mergeDeep } from "@solvari/utils";

import { useAbTesting } from "@/composables/useAbTesting.ts";
import { useMetaStore } from "@/plugins/store/meta";

const useI18nFormsFallback = defineI18n((locale: LocaleIso) => {
  return {
    "nl-NL": {
      loadingError: {
        title: "Oeps! Er gaat iets mis met het ophalen van dit formulier.",
        text: "Probeer het later nog eens. Onze excuses voor het ongemak.",
      },
    },
    "nl-BE": {
      loadingError: {
        title: "Oeps! Er gaat iets mis met het ophalen van dit formulier.",
        text: "Probeer het later nog eens. Onze excuses voor het ongemak.",
      },
    },
    "fr-BE": {
      loadingError: {
        title: "Oups! Nous n’arrivons pas à charger ce formulaire.",
        text: "Veuillez réessayer plus tard. Désolé pour le désagrément.",
      },
    },
    "sv-SE": {
      loadingError: {
        title:
          "hoppsan! Det uppstod ett fel när det här formuläret skulle hämtas.",
        text: "Försök igen senare. Vi ber om ursäkt för besväret.",
      },
    },
    "da-DK": {
      loadingError: {
        title: "Ups! Der opstod en fejl under hentning af denne formular.",
        text: "Prøv venligst igen senere. Vi beklager ulejligheden.",
      },
    },
    "fr-FR": {
      loadingError: {
        title: "Oups! Nous n’arrivons pas à charger ce formulaire.",
        text: "Veuillez réessayer plus tard. Désolé pour le désagrément.",
      },
    },
  }[locale];
});

const useI18nForms = defineI18n(async (locale: LocaleIso) => {
  const translations = await ky
    .get(
      `${
        getEnv().network.cdn
      }/morpheus/translations/${localeToMorpheus(locale)}/forms.json`,
    )
    .json<typeof FormsTranslations>();
  const translationsProp = useMetaStore().translations?.[locale] ?? {};
  const abTestTranslations = useAbTesting().translations?.[locale] ?? {};

  return (
    [translations, translationsProp, abTestTranslations] as Record<
      string,
      string
    >[]
  ).reduce(mergeDeep, {}) as typeof translations;
});

export { useI18nForms, useI18nFormsFallback };
