import { type MaybeRefOrGetter, type Ref, toValue } from "vue";
import { computed, toRef } from "vue";

import type {
  EventsCallback,
  ValidationRule,
  ValidationRuleBase,
} from "@/lib/validation/validation.types";

import { useLabelFallback } from "@/lib/composables/useLabelFallback.ts";
import { reactivePick } from "@/lib/helpers/reactivity";
import { required } from "@/lib/validation/rules";

type UseRulesProps<ModelValue> = Readonly<{
  errorLabel?: string;
  label?: string;
  name: string;
  required: boolean;
  rules: ValidationRuleBase<NoInfer<ModelValue>>[];
  validationEvents: EventsCallback<NoInfer<ModelValue>> | string[];
}>;

function useRules<ModelValue>(
  modelValue: MaybeRefOrGetter<ModelValue>,
  props: UseRulesProps<ModelValue>,
  error: Ref<string | null>,
) {
  const inferredRules = computed(() => {
    const rules = [];
    if (props.required) {
      rules.push(required());
    }

    return rules;
  });

  const allRules = computed(() => {
    const rules = [...inferredRules.value, ...props.rules];
    return mergeRules(rules)
      .map((rule) => fillInRuleDefaults(rule))
      .sort(
        (first, second) => Number(second.blocking) - Number(first.blocking),
      );
  });

  const blockingRules = computed(() => {
    return allRules.value.filter((rule) => rule.blocking);
  });

  function mergeRules(rules: ValidationRuleBase<ModelValue>[]) {
    return rules.reduce((merged: ValidationRuleBase<ModelValue>[], rule) => {
      const existingRule = merged.find(({ name }) => name === rule.name);

      if (existingRule) {
        const existingRuleIndex = merged.findIndex(
          ({ name }) => name === rule.name,
        );
        merged[existingRuleIndex] = { ...existingRule, ...rule };
      } else {
        merged.push(rule);
      }

      return merged;
    }, []);
  }

  const { errorLabel } = useLabelFallback(
    toRef(() => props.name),
    reactivePick(props, ["errorLabel", "label"]),
  );

  function fillInRuleDefaults(
    rule: ValidationRuleBase<ModelValue>,
  ): ValidationRule {
    const events = () => {
      const ruleEvents = rule.events || props.validationEvents;

      return typeof ruleEvents === "function"
        ? ruleEvents(error.value, toValue(modelValue))
        : ruleEvents;
    };

    return {
      blocking: true,
      color: "danger",
      ...rule,
      events: events(),
      message: rule.message(errorLabel.value, toValue(modelValue)) || rule.name,
    } as ValidationRule;
  }

  return {
    allRules,
    blockingRules,
  };
}

export type { UseRulesProps };
export { useRules };
