import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
// eslint-disable-next-line import/no-duplicates
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

import { singleton } from "./memoize.ts";
import { getEnv } from "./solvariEnv.ts";

import "dayjs/locale/fr";
import "dayjs/locale/nl";
// eslint-disable-next-line import/no-duplicates
import "dayjs/plugin/relativeTime";

const dayjsInstance = singleton(() => {
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  dayjs.extend(LocalizedFormat);

  dayjs.locale(getEnv().config.lang);

  dayjs.updateLocale("nl", {
    calendar: {
      lastDay: "[Gisteren]",
      sameDay: "[Vandaag]",
      nextDay: "[Morgen]",
      lastWeek: "dd D MMMM",
      nextWeek: "dd D MMMM",
      sameElse: "dd D MMMM",
    },
  });

  dayjs.updateLocale("fr", {
    calendar: {
      lastDay: "[Hier]",
      sameDay: "[Aujourd'hui]",
      nextDay: "[Demain]",
      lastWeek: "dd D MMMM",
      nextWeek: "dd D MMMM",
      sameElse: "dd D MMMM",
    },
  });

  return dayjs;
});

const sDayjs = (date?: dayjs.ConfigType) => dayjsInstance()(date);

function toDate(value: Date | number | string): string {
  if (!value) {
    return value.toString();
  }
  return sDayjs(value.toString()).format("DD-MM-YYYY");
}

function toDateMedium(value: Date | number | string): string {
  if (!value) {
    return value.toString();
  }
  return sDayjs(value.toString()).format("D MMM YYYY");
}

function toDateTime(value: Date | number | string): string {
  if (!value) {
    return value.toString();
  }
  return sDayjs(value.toString()).format("DD-MM-YYYY HH:mm:ss");
}

export { sDayjs as dayjs, toDate, toDateMedium, toDateTime };
