import type { DeepReadonly } from "vue";

function constructStore<Props extends DeepReadonly<unknown>, Store>(
  initStore: (props: Props) => () => Store,
) {
  let store: (() => Store) | undefined;

  function init(props: Props) {
    if (store) {
      store = undefined;
    }
    store = initStore(props);
    return store();
  }

  function use() {
    if (!store) {
      throw new Error("Store was accessed before initialization");
    }
    return store();
  }

  return { init, use };
}

export { constructStore };
