import type { App } from "vue";

import type {
  SentryProject,
  WindowSentry,
} from "@/lib/integrations/sentry/types";
/*
  Gets the global Sentry instance, this avoids having to include Sentry in every micro frontend
 */
function getSentry(): Required<WindowSentry> {
  return {
    captureException: window.sentry?.captureException ?? console.error,
    captureMessage: window.sentry?.captureMessage ?? console.error,
    trackVueErrors: window.sentry?.trackVueErrors ?? trackVueErrorsFallback,
  };
}

function addSentryProject(project: SentryProject) {
  window.sentryProjects = window.sentryProjects ?? {};
  window.sentryProjects[project.project] = {
    ...project,
    release: import.meta.env.npm_package_version as string,
  };
}

function trackVueErrorsFallback(vueApp: App) {
  vueApp.config.errorHandler = (error) => getSentry().captureException(error);
  vueApp.config.warnHandler = (error) => getSentry().captureMessage(error);
}

export { addSentryProject, getSentry };
