import type { Ref } from "vue";

import { computed } from "vue";

function useLabelFallback(
  name: Readonly<Ref<string>>,
  props: Partial<{ errorLabel: string | null; label: string | null }>,
) {
  return {
    label: computed(() => props.label ?? name.value),
    errorLabel: computed(() => props.errorLabel ?? props.label ?? name.value),
  };
}

export { useLabelFallback };
