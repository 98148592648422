<template>
  <div class="s-form-submit-error">
    <div class="s-form-submit-error__title">{{ tr("forms.error.title") }}</div>
    <p class="s-form-submit-error__text" v-html="tr('forms.error.text')" />
  </div>
</template>

<script lang="ts" setup>
import { useI18nForms } from "@/plugins/i18n.ts";

const { tr } = useI18nForms();
</script>

<style lang="postcss">
.s-form-submit-error {
  @apply rounded border border-danger-200 bg-danger-100 p-8;

  &__title {
    @apply mb-4 font-secondary text-2xl font-bold text-danger-600;
  }

  &__text {
    @apply text-gray-800;
  }
}
</style>
