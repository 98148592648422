<template>
  <div
    class="s-address--zipcode-city"
    :class="{ [`s-address--zipcode-city--size-${size}`]: !!size }"
  >
    <SAutoComplete
      v-model="zipcodeCity"
      auto-option-focus
      autocomplete="postal-code"
      class="s-address--zipcode-city__zipcode-city"
      :label="tr('address_input.zipcode_city.label')"
      :loading="zipcodeCitySuggestionsFetching"
      name="zipcodeCity"
      option-label=""
      :placeholder="tr('address_input.zipcode_city.placeholder')"
      :rules="[
        required(undefined, {
          message: () => tr('address_input.zipcode_city.validation.required'),
        }),
        localeSwitchRule(),
        patternRule(
          [zipcodeRegex[locale], /^\D+$/iu, /^[1-9][0-9]{3} - \D+$/iu],
          { message: () => tr('address_input.zipcode_city.validation.regex') },
        ),
        zipcodeCityNlBeExistsRule(locale),
      ]"
      :size
      :suggestions="zipcodeCitySuggestions"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
      @blur="attemptApplyValidSuggestion"
      @focus="emit('focus', 'zipcodeCity')"
    >
      <template #manualToggle>
        <PMessage class="s-address--zipcode-city__manual" :size="size">
          {{ tr("address_input.show_manual.text") }} <br />
          <PButton
            icon="far fa-chevron-right"
            icon-pos="right"
            :label="tr('address_input.show_manual.button')"
            link
            size="small"
            text
            @click="emit('showManual')"
          />
        </PMessage>
      </template>

      <template #localeSwitch>
        <PMessage
          class="s-address--zipcode-city__locale-switch"
          icon="far fa-circle-exclamation"
          :size="size"
        >
          {{ tr("address_input.locale_warning.text") }} <br />
          <PButton
            icon="far fa-chevron-right"
            icon-pos="right"
            :label="tr('address_input.locale_warning.button')"
            link
            size="small"
            text
            @click="switchLocale"
          />
        </PMessage>
      </template>
    </SAutoComplete>

    <SAutoComplete
      v-model="street"
      autocomplete="address-line1"
      class="s-address--zipcode-city__street"
      :label="tr('address_input.street.label')"
      :loading="streetSuggestionsFetching"
      name="street"
      option-label=""
      :placeholder="tr('address_input.street.placeholder')"
      :rules="[
        required(undefined, {
          message: () => tr('address_input.street.validation.required'),
        }),
        streetExistsRule(),
      ]"
      :size
      :suggestions="streetSuggestions"
      :validation-events="onCreatedIfValue(eager)"
      :validation-trigger="streetValidationTrigger"
      v-on="listeners"
    />

    <STextInput
      v-model="houseNumber"
      autocomplete="address-line2"
      class="s-address--zipcode-city__house-number"
      :label="tr('address_input.house_number.label')"
      :maxlength="255"
      name="houseNumber"
      :rules="[
        required(undefined, {
          message: () => tr('address_input.house_number.validation.required'),
        }),
      ]"
      :size
      v-on="listeners"
    />
  </div>
</template>

<script setup lang="ts">
import type { ValidateEvent } from "@solvari/common-fe/validation";

import { reEmit, useModel } from "@solvari/common-fe/composables";
import { alphabeticCompare } from "@solvari/common-fe/helpers";
import {
  defineRule,
  eager,
  getStreetSuggestions,
  getZipcodeCitySuggestions,
  onCreatedIfValue,
  patternRule,
  required,
  splitZipcodeCity,
  zipcodeCityNlBeExistsRule,
  zipcodeRegex,
} from "@solvari/common-fe/validation";
import { computedAsync, watchImmediate } from "@vueuse/core";
import { ref, watch, watchEffect } from "vue";

import type { LocaleIso } from "@solvari/translations";
import { useI18nAddressInput } from "@solvari/translations";
import { asyncThrottle } from "@solvari/utils";

import SAutoComplete from "@/molecules/SAutoComplete.vue";
import STextInput from "@/molecules/STextInput.vue";
import { PButton, PMessage } from "@/primeVueExports.ts";

import { useLocaleSwitch } from "./useLocaleSwitch";

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  locale: Extract<LocaleIso, "da-DK" | "fr-BE" | "nl-BE">;
  size?: "large" | "small";
}>();

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "showManual"): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const { tr } = useI18nAddressInput();
const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcode = defineModel<string | null>("zipcode", { required: true });
const houseNumber = defineModel<string | null>("houseNumber", {
  required: true,
});
const city = defineModel<string | null>("city", { required: true });
const street = defineModel<string | null>("street", { required: true });

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);

/*
  ZipcodeCity
 */
const zipcodeCity = ref("");
watch(zipcodeCity, (zipcodeCity) => {
  const split = splitZipcodeCity(zipcodeCity);

  zipcode.value = split.zipcode || "";
  city.value = split.city;
});

watchEffect(() => {
  if (zipcode.value && city.value) {
    zipcodeCity.value = `${zipcode.value} - ${city.value}`;
    return;
  }
  if (zipcode.value) {
    zipcodeCity.value = zipcode.value;
  }
  if (city.value) {
    zipcodeCity.value = city.value;
  }
});

const suggestZipcodeCity = asyncThrottle(getZipcodeCitySuggestions);

const zipcodeCitySuggestionsFetching = ref(false);
const zipcodeCitySuggestions = computedAsync(
  async () => {
    const result = await suggestZipcodeCity({
      city: city.value,
      locale: props.locale,
      zipcode: zipcode.value,
    });
    if (result instanceof Error) {
      return [];
    }
    return result.map(({ zipcode, city }) => `${zipcode} - ${city}`);
  },
  [],
  {
    evaluating: zipcodeCitySuggestionsFetching,
  },
);

async function attemptApplyValidSuggestion() {
  const result = await suggestZipcodeCity({
    city: city.value,
    locale: props.locale,
    zipcode: zipcode.value,
  });

  if (result instanceof Error) {
    emit("showManual");
    return;
  }

  const validSuggestion = result.find(
    (suggestion) =>
      suggestion.zipcode === zipcode.value ||
      (city.value && alphabeticCompare(suggestion.city, city.value)),
  );

  if (validSuggestion) {
    zipcodeCity.value = `${validSuggestion.zipcode} - ${validSuggestion.city}`;
  }
}
void attemptApplyValidSuggestion();

/*
  Street
 */
const suggestStreet = asyncThrottle(getStreetSuggestions);

const streetSuggestionsFetching = ref(false);
const streetSuggestions = computedAsync(
  async () => {
    const result = await suggestStreet({
      locale: props.locale,
      zipcode: zipcode.value,
      street: street.value,
    });
    return result instanceof Error ? [] : result;
  },
  [],
  { lazy: true, evaluating: streetSuggestionsFetching },
);

const streetExistsRule = defineRule({
  name: "exists",
  validate: async (street: unknown) => {
    if (!street || typeof street !== "string") {
      return false;
    }
    const result = await suggestStreet({
      locale: props.locale,
      zipcode: zipcode.value,
      street,
    });
    return (
      result instanceof Error ||
      result.some((suggestion) => alphabeticCompare(suggestion, street))
    );
  },
  events: ["blur", "update:zipcode"],
  color: "warning",
  blocking: false,
  message: () => tr("address_input.street.validation.exists"),
});

function streetValidationTrigger(validateEvent: ValidateEvent) {
  watchImmediate(zipcode, () => {
    if (street.value) {
      void validateEvent("update:zipcode");
    }
  });
}
</script>

<style lang="postcss">
.s-address--zipcode-city {
  @apply grid gap-4;
  grid-template-areas:
    "zipcode-city zipcode-city zipcode-city"
    "street street house-number";
  grid-template-columns: minmax(min-content, 1fr) 1fr minmax(min-content, 1fr);

  &__manual,
  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }

  &--size {
    &-small {
      @apply gap-3;
    }

    &-large {
      @apply gap-5;
    }
  }

  &__zipcode-city {
    grid-area: zipcode-city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }
}
</style>
