<template>
  <BaseInput
    v-bind="props"
    :class="props.class"
    v-on="
      reEmit(emit, ['update:loading', 'validationError', 'update:modelValue'])
    "
  >
    <template #input="{ id, describedBy, errorId, listeners, invalid }">
      <PAutoComplete
        v-bind="$attrs"
        v-model="modelValue"
        :aria-describedby="describedBy"
        :aria-errormessage="errorId"
        :disabled
        fluid
        :input-id="id"
        :invalid
        :name
        :option-label
        :required
        :size
        :suggestions="suggestions"
        v-on="listeners"
        @focus="emit('focus', name)"
      />
    </template>
    <template v-for="(_index, slotName) in $slots" #[slotName]="data">
      <slot :name="slotName" v-bind="data" />
    </template>
  </BaseInput>
</template>

<script setup lang="ts">
import type {
  AutoCompletePassThroughOptions,
  AutoCompleteProps,
} from "primevue";

import { useModel } from "@solvari/common-fe/composables";
import { reEmit } from "@solvari/common-fe/helpers";

import type {
  BaseInputEmits,
  BaseInputPassThroughOptions,
  BaseInputProps,
} from "@/molecules/baseInput.ts";

import BaseInput from "@/molecules/BaseInput.vue";
import { PAutoComplete } from "@/primeVueExports.ts";

const props = withDefaults(
  defineProps<
    BaseInputProps<unknown> &
      /* @vue-ignore */ Omit<AutoCompleteProps, "invalid"> & {
        class?: string;
        optionLabel?: string;
        pt?: BaseInputPassThroughOptions & {
          pcInput?: AutoCompletePassThroughOptions;
        };
        suggestions?: unknown[];
      }
  >(),
  { optionLabel: "label" },
);
const emit = defineEmits<
  BaseInputEmits & {
    (event: "focus", name: string): void;
    (event: "update:modelValue", value: unknown): void;
  }
>();

const modelValue = useModel("modelValue", props, emit);
</script>
