import type { ObjectToSnake } from "ts-case-convert/lib/caseConvert";

import { withKyErrors, ziggy } from "@solvari/common-fe/integrations";
import ky from "ky";

import {
  type LocaleIso,
  localeToCountry,
  localeToLanguage,
} from "@solvari/translations";

const getAddressSuggestions = withKyErrors(
  async ({
    locale,
    query,
    sessionId,
    types = "address,street",
  }: {
    locale: LocaleIso;
    query: string;
    sessionId: string;
    types?: string;
  }): Promise<MapBoxAutocompleteItem[]> => {
    const { suggestions } = await ky
      .get(ziggy("api.geocoder.mapbox_autocomplete"), {
        searchParams: {
          // eslint-disable-next-line id-length
          q: query,
          language: localeToLanguage(locale).toLowerCase(),
          country: localeToCountry(locale).toLowerCase(),
          types,
          session_token: sessionId,
        },
      })
      .json<ObjectToSnake<MapboxResponse>>();

    return suggestions.map<MapBoxAutocompleteItem>(
      ({ context, name, place_formatted: placeFormatted }) => {
        return {
          label: `${name}, ${placeFormatted}`,
          city: context.place.name,
          houseNumber: context.address?.address_number || null,
          street: context.street.name,
          zipcode: context.postcode.name,
        };
      },
    );
  },
  { timeoutError: false },
);

export { getAddressSuggestions };

//  https://docs.mapbox.com/api/search/search-box/
type MapboxResponse = {
  suggestions: MapboxSuggestion[];
};

type MapboxSuggestion = {
  context: {
    address?: {
      addressNumber: string;
    };
    country: {
      name: string;
    };
    place: {
      name: string;
    };
    postcode: {
      name: string;
    };
    street: {
      name: string;
    };
  };
  name: string;
  placeFormatted: string;
};

export type MapBoxAutocompleteItem = {
  city: string;
  houseNumber: string | null;
  label: string;
  street: string;
  zipcode: string;
};
