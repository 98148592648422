<template>
  <STooltip v-bind="tooltip.props">
    <component
      :is="tag"
      v-bind="buttonProps"
      class="s-button-icon"
      :class="{
        [`s-button-icon--action-${actionType}`]: true,
        [`s-button-icon--color-${color}`]: true,
        [`s-button-icon--size-${size}`]: true,
        's-button-icon--disabled': disabled,
      }"
      @click="click"
    >
      <i class="s-button-icon__icon" :class="icon"></i>
    </component>
  </STooltip>
</template>

<script setup lang="ts">
import type { UseButtonIconEmit } from "@/lib/components/logic/atoms/useButtonIcon";

import STooltip from "@/lib/components/atoms/tooltip/STooltip.vue";
import * as useButtonIcon from "@/lib/components/logic/atoms/useButtonIcon";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";
import { pickProps } from "@/lib/composables/componentComposable";

const props = defineProps({ ...useButtonIcon.props, ...useTooltip.props });
const emit = defineEmits<UseButtonIconEmit>();

const tooltip = { props: pickProps(props, useTooltip.props) };

const { icon, tag, buttonProps, actionType, click, size, color, disabled } =
  useButtonIcon.use(props, emit);
</script>

<style lang="postcss">
.s-button-icon {
  @apply inline-flex cursor-pointer items-center justify-center rounded-full transition-colors;

  &:hover {
    @apply bg-gray-100;
  }

  &--color {
    &-primary {
      --button-color-500: theme("colors.primary.700");
      --button-color-400: theme("colors.primary.600");
      --button-color-200: theme("colors.primary.200");
      --button-color-100: theme("colors.primary.100");
    }

    &-accent {
      --button-color-500: theme("colors.accent.500");
      --button-color-400: theme("colors.accent.400");
      --button-color-200: theme("colors.accent.200");
      --button-color-100: theme("colors.accent.100");
    }

    &-success {
      --button-color-500: theme("colors.success.500");
      --button-color-400: theme("colors.success.400");
      --button-color-200: theme("colors.success.200");
      --button-color-100: theme("colors.success.100");
    }

    &-warning {
      --button-color-500: theme("colors.warning.500");
      --button-color-400: theme("colors.warning.400");
      --button-color-200: theme("colors.warning.200");
      --button-color-100: theme("colors.warning.100");
    }

    &-danger {
      --button-color-500: theme("colors.danger.500");
      --button-color-400: theme("colors.danger.400");
      --button-color-200: theme("colors.danger.200");
      --button-color-100: theme("colors.danger.100");
    }

    &-gray {
      --button-color-500: theme("colors.gray.500");
      --button-color-400: theme("colors.gray.400");
      --button-color-200: theme("colors.gray.200");
      --button-color-100: theme("colors.gray.100");
    }
  }

  &--action {
    &-primary {
      @apply text-white;
      background-color: var(--button-color-500);

      &:hover {
        background-color: var(--button-color-400);
      }
    }

    &-secondary {
      color: var(--button-color-500);
      background-color: var(--button-color-100);

      &:hover {
        @apply text-white;
        background-color: var(--button-color-400);
      }
    }

    &-tertiary {
      color: var(--button-color-500);
      border: 1px solid var(--button-color-500);

      &:hover {
        background-color: var(--button-color-100);
        border-color: var(--button-color-400);
      }
    }

    &-quaternary {
      color: var(--button-color-500);

      &:hover {
        color: var(--button-color-500);
        background-color: var(--button-color-100);
      }
    }
  }

  &--size {
    &-lg {
      @apply h-12 w-12;

      .s-button-icon__icon {
        @apply h-5 w-5;
      }
    }

    &-md {
      @apply h-10 w-10;

      .s-button-icon__icon {
        @apply h-4 w-4;
      }
    }

    &-sm {
      @apply h-8 w-8;

      .s-button-icon__icon {
        @apply h-3.5 w-3.5;
      }
    }

    &-inline {
      @apply -mx-1 inline-flex h-6 w-6;

      .s-button-icon__icon {
        @apply h-4 w-4;
      }
    }
  }

  &--disabled,
  &--disabled:hover {
    @apply cursor-not-allowed border-none bg-gray-100 text-gray-500;
  }
}
</style>
