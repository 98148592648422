import { isString } from "radash";

import {
  isLocaleIso,
  isLocaleMorpheus,
  localeToIso,
  useI18nAddressInput,
} from "@solvari/translations";

import { alphabeticCompare } from "@/lib/helpers/strings";
import { onCreatedIfValue } from "@/lib/validation/events";
import { defineRule } from "@/lib/validation/rules/defineRule";

import {
  autocompleteNlNlAddress,
  getZipcodeCitySuggestions,
} from "./address.api.ts";
import { zipcodeRegex } from "./zipcodeRegex.ts";

const zipcodeRule = defineRule({
  name: "zipcode",
  validate: (value: number | string | null | undefined, locale: string) => {
    if (!isLocaleIso(locale) && !isLocaleMorpheus(locale)) {
      return true;
    }
    const localeIso = localeToIso(locale);
    if (!value) {
      return false;
    }
    return zipcodeRegex[localeIso].test(value.toString());
  },
  message: () =>
    useI18nAddressInput().tr("address_input.zipcode.validation.pattern"),
});

const zipcodeCityNlBeExistsRule = defineRule({
  name: "exists",
  validate: async (
    zipcodeCity: unknown,
    locale: "da-DK" | "fr-BE" | "nl-BE",
  ) => {
    if (!zipcodeCity || typeof zipcodeCity !== "string") {
      return false;
    }
    const { zipcode, city } = splitZipcodeCity(zipcodeCity);

    const result = await getZipcodeCitySuggestions({
      city,
      locale,
      zipcode,
    });

    return (
      !(result instanceof Error) &&
      !!result.find(
        (suggestion) =>
          suggestion.zipcode === zipcode ||
          alphabeticCompare(suggestion.city, city),
      )
    );
  },
  events: onCreatedIfValue(["blur"]),
  component: "manualToggle",
  color: "warning",
  message: () =>
    useI18nAddressInput().tr("address_input.zipcode_city.validation.exists"),
});

function splitZipcodeCity(zipcodeCity: string) {
  const zipcodeCityWords = zipcodeCity.split(" ");

  const zipcode =
    zipcodeCityWords.find((zipcodeCityPart) =>
      /^\d+[a-z]*$/i.test(zipcodeCityPart),
    ) || "";
  const city = zipcodeCityWords
    .filter((zipcodeCityPart) => {
      return zipcodeCityPart !== "-" && /^\D+$/iu.test(zipcodeCityPart);
    })
    .join(" ");

  return { zipcode, city };
}

async function zipcodeNlNlExists(value: number | string | null | undefined) {
  if (!isString(value) || !zipcodeRule("nl-NL").validate(value)) {
    return false;
  }
  const autocompleteResult = await autocompleteNlNlAddress({
    zipcode: value.replace(" ", "").toUpperCase(),
  });
  return !!autocompleteResult && !(autocompleteResult instanceof Error);
}

const zipcodeExistsRule = defineRule({
  name: "exists",
  validate: async (
    value: number | string | null | undefined,
    locale: "fr-BE" | "nl-BE" | "nl-NL",
  ) => {
    if (!isString(value)) {
      return false;
    }
    if (locale === "nl-NL") {
      return await zipcodeNlNlExists(value);
    }
    return await zipcodeCityNlBeExistsRule(locale).validate(value);
  },
  events: onCreatedIfValue(["blur"]),
  color: "warning",
  blocking: false,
  message: () =>
    useI18nAddressInput().tr("address_input.zipcode.validation.exists"),
});

export {
  splitZipcodeCity,
  zipcodeCityNlBeExistsRule,
  zipcodeExistsRule,
  zipcodeRule,
};
