import type { SFileLocal } from "@solvari/common-fe/composables";

import { toRef } from "@vueuse/core";
import { defineStore } from "pinia";
import { diff, isObject, mapValues } from "radash";
import { watch, watchEffect } from "vue";

import { arrayWrap } from "@solvari/utils";

import type { Additional, FormComponentAll } from "@/helpers/types";

import { getAddressMeta } from "@/api/meta.api";
import { useFormStore } from "@/plugins/store/form";

/*
  Stores data about the data the user has filled in
 */
export const useLeadStore = defineStore("lead", {
  state: () => ({
    productId: null as number | null,

    additional: {} as Additional,
    description: "",

    city: "",
    houseNumber: "",
    zipcode: "",
    street: "",
    constructionYear: null as number | null,
    surface: null as number | null,

    email: "",
    firstName: "",
    title: "mr" as "mr" | "mrs",
    lastName: "",
    phone: "",
    newsletter: false,
    whatsappAgreement: false,
  }),
  getters: {
    getLead: (state) => ({
      productId: (state.productId || useFormStore().product?.id) ?? 0,

      additional: mapValues(state.additional, (value) => {
        const isFileAnswer = (
          answerValue: typeof value,
        ): answerValue is SFileLocal[] => {
          return (
            Array.isArray(answerValue) &&
            isObject(answerValue[0]) &&
            "file" in answerValue[0]
          );
        };

        return isFileAnswer(value) ? value.map(({ file }) => file) : value;
      }),

      description: state.description,

      city: state.city,
      houseNr: state.houseNumber,
      street: state.street,
      zipCode: state.zipcode,

      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
      phone: state.phone,
      title: state.title,
      newsletter: state.newsletter,
      whatsappAgreement: state.whatsappAgreement,
    }),
    wasChanged: (state) => {
      return (
        !!state.description ||
        !!state.city ||
        !!state.houseNumber ||
        !!state.zipcode ||
        !!state.street ||
        !!state.email ||
        !!state.firstName ||
        !!state.lastName ||
        !!state.phone
      );
    },
  },
  actions: {
    setAdditional({
      question,
      value,
    }: {
      question: FormComponentAll;
      value: number[] | SFileLocal[] | number | string;
    }) {
      const oldValue = arrayWrap(
        this.additional[question.id] ?? [],
      ) as number[];
      this.additional[question.id] = value;

      if (!("answers" in question)) {
        return;
      }

      const valueToAnswer = (value: number) =>
        question.answers.find((answer) => answer.value === value)!;

      const formStore = useFormStore();

      const newValue = arrayWrap(value) as number[];

      diff(newValue, oldValue)
        .map(valueToAnswer)
        .forEach(({ crossSellFormId, product }) => {
          if (product) {
            this.productId = product;
          }
          if (crossSellFormId) {
            formStore.crossSellFormIds.add(crossSellFormId);
          }
        });

      diff(oldValue, newValue)
        .map(valueToAnswer)
        .forEach(({ crossSellFormId }) => {
          if (crossSellFormId) {
            formStore.crossSellFormIds.delete(crossSellFormId);
          }
        });
    },
    initStoreWatchers(
      props: Readonly<{
        city: string | null;
        houseNumber: string | null;
        street: string | null;
        zipcode: string | null;
      }>,
    ) {
      watchEffect(() => (this.zipcode = props.zipcode ?? ""));
      watchEffect(() => (this.houseNumber = props.houseNumber ?? ""));
      watchEffect(() => (this.city = props.city ?? ""));
      watchEffect(() => (this.street = props.street ?? ""));

      watchEffect(async () => {
        const addressMeta = await getAddressMeta({
          locale: useFormStore().locale!,
          zipcode: this.zipcode,
          houseNumber: this.houseNumber,
          city: this.city,
          street: this.street,
        });

        this.constructionYear = addressMeta?.constructionYear ?? null;
        this.surface = addressMeta?.surface ?? null;
      });

      watch(
        toRef(() => useFormStore().formGetResult),
        () => {
          this.additional = {};
          this.productId = null;
        },
      );
    },
  },
});
