<template>
  <SAlert
    v-if="href && !formStore.isCrossSellForm"
    class="s-form-submit-success"
    color="success"
    prefix-icon="fa-solid fa-circle-info"
    suffix-icon="fa-solid fa-circle-check"
  >
    <span v-html="tr('forms.submitSuccess.text', { url: href })" />
  </SAlert>
</template>

<script setup lang="ts">
import { applyArgusUuidToUrl } from "@solvari/common-fe/argus/thin-client";
import { computed } from "vue";

import { SAlert } from "@solvari/common-fe";

import { useI18nForms } from "@/plugins/i18n.ts";
import { useApplicationStore } from "@/plugins/store/application";
import { useFormStore } from "@/plugins/store/form";

const applicationStore = useApplicationStore();
const formStore = useFormStore();

const href = computed(() => {
  if (!applicationStore.submitResult?.waitPageUrl) {
    return;
  }
  return applyArgusUuidToUrl(applicationStore.submitResult.waitPageUrl);
});

const { tr } = useI18nForms();
</script>
