<template>
  <component
    :is="tag"
    :id="id"
    class="s-label"
    :class="{
      [$style.root]: true,
      [$style.disabled]: disabled,
      [$style.strong]: strong,
      [$style[`size-${size}`]!]: !!size,
    }"
    :for="props.for"
    v-bind="pt?.root"
  >
    <slot />
    <span v-if="suffix" :class="$style.suffix" v-bind="pt?.suffix">
      {{ suffix }}
    </span>
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { useI18nCommonFe } from "@solvari/translations";

export type SLabelPassThroughOptions = {
  root?: Record<string, unknown>;
  suffix?: Record<string, unknown>;
};

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    for?: string;
    id?: string;
    pt?: SLabelPassThroughOptions;
    required?: boolean;
    showRequiredType?: "none" | "optional" | "required";
    showValidState?: boolean;
    size?: "large" | "small";
    strong?: boolean;
    tag?: "label" | "legend" | "span";
  }>(),
  { showRequiredType: "required", tag: "label", strong: true },
);

defineSlots<{ default(): never }>();

const { tr } = useI18nCommonFe();

const suffix = computed(() => {
  if (props.showRequiredType === "optional" && !props.required) {
    return tr("common_fe.fields.optional");
  }

  if (props.showRequiredType === "required" && props.required) {
    return "*";
  }

  return null;
});
</script>

<style module lang="postcss">
.root {
  @apply mb-0 text-base text-gray-900;
}

.strong {
  @apply font-medium;
}

.disabled {
  @apply text-gray-600;

  .suffix {
    @apply text-gray-500;
  }
}

.size {
  &-large {
    @apply text-lg;
  }

  &-small {
    @apply text-sm;
  }
}

.suffix {
  @apply ml-2 text-gray-700;
}
</style>
