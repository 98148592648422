import type { DefaultLocaleMessageSchema, I18n } from "vue-i18n";

import { omit } from "radash";
import {
  computed,
  type ComputedRef,
  getCurrentInstance,
  ref,
  type Ref,
  type WritableComputedRef,
} from "vue";
import { createI18n, useI18n as useI18nBase } from "vue-i18n";

import { getEnv, memoize, singleton } from "@solvari/utils";

import {
  type LocaleIso,
  type LocaleMorpheus,
  localeToIso,
  localeToMorpheus,
} from "@/enums/locales.ts";

type Paths<Obj, Prefix extends string = ""> = {
  [Key in keyof Obj]: Obj[Key] extends string
    ? `${Prefix}${Key & string}`
    : Paths<Obj[Key], `${Prefix}${Key & string}.`>;
}[keyof Obj];

type Tr<Keys extends string> = (
  key: Keys,
  params?: Record<string, number | string>,
) => string;

type TrOptional<Keys extends string> = (
  key: Keys | string,
  params?: Record<string, number | string>,
) => string | undefined;

type I18nInstance = I18n<
  Record<LocaleIso | LocaleMorpheus, DefaultLocaleMessageSchema>,
  Record<LocaleIso | LocaleMorpheus, unknown>,
  Record<LocaleIso | LocaleMorpheus, unknown>,
  LocaleIso | LocaleMorpheus,
  false
>;

type SI18nInstance<
  Keys extends string,
  Locale extends LocaleIso | LocaleMorpheus = LocaleIso | LocaleMorpheus,
> = Omit<I18nInstance["global"], "locale" | "t"> & {
  locale: WritableComputedRef<Locale>;
  localeIso: ComputedRef<LocaleIso>;
  localeMorpheus: ComputedRef<LocaleMorpheus>;
  tr: Tr<Keys>;
  trOptional: TrOptional<Keys>;
};

const i18nInstance: { (): I18nInstance; reset: () => void } = singleton(() => {
  return createI18n<
    DefaultLocaleMessageSchema,
    LocaleIso | LocaleMorpheus,
    false
  >({
    legacy: false,
    locale: getEnv().config.localeIso,
    fallbackLocale: "nl-NL",
    fallbackWarn: false,
    missingWarn: false,
  });
});

function useI18n<
  Keys extends string,
  Locale extends LocaleIso | LocaleMorpheus = LocaleIso | LocaleMorpheus,
>(finishedLoading?: Ref<boolean>): SI18nInstance<Keys, Locale> {
  const globalI18n = getCurrentInstance()
    ? useI18nBase()
    : i18nInstance().global;

  function tr(key: Keys, params?: Record<string, number | string>) {
    const translation = (globalI18n.t as Tr<Keys>)(key, params);
    if (finishedLoading === undefined) {
      return translation;
    }
    if (!finishedLoading.value) {
      return translation !== key ? translation : "";
    }
    return translation;
  }

  function trOptional(
    key: Keys | string,
    params?: Record<string, number | string>,
  ) {
    const translation = (globalI18n.t as Tr<Keys>)(key as Keys, params);
    return translation !== key ? translation : undefined;
  }

  const localeIso = computed(() =>
    localeToIso(globalI18n.locale.value as LocaleIso | LocaleMorpheus),
  );
  const localeMorpheus = computed(() =>
    localeToMorpheus(globalI18n.locale.value as LocaleIso | LocaleMorpheus),
  );

  return {
    ...omit(globalI18n, ["t", "locale"]),
    tr,
    trOptional,
    localeIso,
    localeMorpheus,
    locale: globalI18n.locale,
  } as SI18nInstance<Keys, Locale>;
}

export function defineI18n<
  Locale extends LocaleIso | LocaleMorpheus,
  MessageSchema,
  Keys extends string = Paths<MessageSchema>,
>(
  loader: (locale: Locale) => MessageSchema | Promise<MessageSchema>,
): () => SI18nInstance<Keys, Locale> {
  const finishedLoading = ref(false);
  const loadForLocale = memoize(async (locale: Locale) => {
    try {
      i18nInstance().global.mergeLocaleMessage(locale, await loader(locale));
    } finally {
      finishedLoading.value = true;
    }
  });

  return () => {
    const i18n = useI18n<Keys, Locale>(finishedLoading);
    void loadForLocale(i18n.locale.value);
    return i18n;
  };
}

export { i18nInstance, useI18n };
export type { SI18nInstance };
