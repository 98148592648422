import { memoize, singleton } from "@solvari/utils";

import { getSentry } from "@/lib/integrations/sentry/client.ts";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Ziggy: {
    routes?: Record<string, { domain: string; methods: string[]; uri: string }>;
  };
}

const routesByName = singleton((): Record<string, string> => {
  if (!Ziggy.routes) {
    getSentry().captureException(
      new Error("Tried to get ziggy routes but they were not defined"),
    );
    return {};
  }
  return Object.fromEntries(
    Object.entries(Ziggy.routes).map(([name, route]): [string, string] => [
      name,
      `https://${route.domain}/${route.uri === "/" ? "" : route.uri}`,
    ]),
  );
});

const ziggy = memoize(
  (routeName: string, params?: Record<string, number | string>): string => {
    const route = routesByName()[routeName];

    if (!route) {
      getSentry().captureException(
        new Error(
          `Tried to get ziggy route '${routeName}' but it was not defined`,
        ),
      );
      return routeName;
    }

    if (params) {
      return Object.entries(params).reduce((route, [property, value]) => {
        return route.replace(`{${property}}`, String(value));
      }, route);
    }

    return route;
  },
);

export { ziggy };
