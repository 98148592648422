<template>
  <div
    class="s-address--manual"
    :class="{ [`s-address--manual--size-${size}`]: !!size }"
  >
    <STextInput
      v-model="zipcode"
      autocomplete="postal-code"
      class="s-address--manual__zipcode"
      :label="tr('address_input.zipcode.label')"
      :maxlength="6"
      name="zipcode"
      required
      :rules="[
        required(undefined, {
          message: () => tr('address_input.zipcode.validation.required'),
        }),
        localeSwitchRule(),
        zipcodeRule(locale),
      ]"
      show-required-type="none"
      :size
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    >
      <template #localeSwitch>
        <PMessage
          class="s-address--manual__locale-switch"
          color="primary"
          icon="far fa-circle-exclamation"
          :size="size"
        >
          {{ tr("address_input.locale_warning.text") }} <br />
          <PButton
            action-type="link"
            icon="far fa-chevron-right"
            icon-pos="right"
            :label="tr('address_input.locale_warning.button')"
            text
            @click="switchLocale"
          />
        </PMessage>
      </template>
    </STextInput>

    <STextInput
      v-model="city"
      autocomplete="address-level2"
      class="s-address--manual__city"
      :label="tr('address_input.city.label')"
      :maxlength="255"
      name="city"
      required
      show-required-type="none"
      :size
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />

    <STextInput
      v-model="street"
      autocomplete="address-level1"
      class="s-address--manual__street"
      :label="tr('address_input.street.label')"
      :maxlength="255"
      name="street"
      :placeholder="tr('address_input.street.placeholder')"
      required
      show-required-type="none"
      :size
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />

    <STextInput
      v-model="houseNumber"
      autocomplete="address-level2"
      class="s-address--manual__house-number"
      :label="tr('address_input.house_number.label')"
      :maxlength="255"
      name="houseNumber"
      :placeholder="tr('address_input.house_number.placeholder')"
      required
      show-required-type="none"
      :size
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />
  </div>
</template>

<script setup lang="ts">
import { useModel } from "@solvari/common-fe/composables";
import { reEmit } from "@solvari/common-fe/helpers";
import {
  eager,
  onCreatedIfValue,
  required,
  zipcodeRule,
} from "@solvari/common-fe/validation";

import { type LocaleIso, useI18nAddressInput } from "@solvari/translations";

import STextInput from "@/molecules/STextInput.vue";
import { useLocaleSwitch } from "@/organisms/address/useLocaleSwitch.ts";
import { PButton, PMessage } from "@/primeVueExports.ts";

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  locale?: LocaleIso | string;
  size?: "large" | "small";
}>();

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:locale", locale: LocaleIso | string | undefined): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const { tr } = useI18nAddressInput();
const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcode = defineModel<string | null>("zipcode", { required: true });
const houseNumber = defineModel<string | null>("houseNumber", {
  required: true,
});
const city = defineModel<string | null>("city", { required: true });
const street = defineModel<string | null>("street", { required: true });

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);
</script>

<style lang="postcss">
.s-address--manual {
  @apply grid gap-4;
  grid-template-areas:
    "zipcode city city"
    "street street house-number";
  grid-template-columns: minmax(min-content, 1fr) 1fr minmax(min-content, 1fr);

  &--size {
    &-small {
      @apply gap-3;
    }

    &-large {
      @apply gap-5;
    }
  }

  &__zipcode {
    grid-area: zipcode;
  }

  &__zipcode-city {
    grid-area: zipcode-city;
  }

  &__city {
    grid-area: city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }

  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }
}
</style>
