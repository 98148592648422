import { unique } from "radash";
import { computed, type PropType, toRef } from "vue";

import type { Color, Size } from "@/lib/components/types";
import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

export type AnchorTarget = "_blank" | "_parent" | "_self" | "_top" | null;
export type ButtonType = "button" | "reset" | "submit";

export type ButtonPropsA = {
  href: string;
  rel?: string | null;
  target?: AnchorTarget;
};

export type ButtonPropsButton = {
  disabled: boolean;
  type: ButtonType;
};

const props = propsDefinition({
  type: {
    type: String as PropType<"button" | "reset" | "submit">,
    default: "button",
    validator: (value: string) => ["button", "reset", "submit"].includes(value),
  },
  actionType: {
    type: String as PropType<
      "link" | "primary" | "quaternary" | "secondary" | "tertiary"
    >,
    default: "primary",
    validator: (value: string) =>
      ["primary", "secondary", "tertiary", "quaternary", "link"].includes(
        value,
      ),
  },
  color: {
    type: String as PropType<Color>,
    default: "primary",
    validator: (value: string) =>
      ["primary", "accent", "success", "warning", "danger", "gray"].includes(
        value,
      ),
  },
  size: {
    type: String as PropType<Size | "inline">,
    default: "md",
    validator: (value: string) => ["lg", "md", "sm", "inline"].includes(value),
  },
  prefixIcon: { type: String, required: false },
  suffixIcon: { type: String, required: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  href: { type: String, required: false },
  target: { type: String as PropType<AnchorTarget>, required: false },
  rel: { type: String, required: false },
});

type UseButtonProps = DefineProps<typeof props>;
type UseButtonEmit = (event: "click", value: PointerEvent) => void;

const emits = emitsDefinition(["click"]);

function use(props: UseButtonProps, emit: UseButtonEmit) {
  const internalPrefixIcon = computed(() => {
    if (!props.prefixIcon && props.suffixIcon) {
      return null;
    }
    return props.loading
      ? "fa-regular fa-spinner-third fa-spin"
      : props.prefixIcon;
  });

  const internalSuffixIcon = computed(() => {
    if (props.loading && props.suffixIcon) {
      return "fa-regular fa-spinner-third fa-spin";
    }

    return props.suffixIcon;
  });

  const isDisabled = computed(() => {
    return props.loading || props.disabled;
  });

  const tag = computed(() => {
    if (props.href) {
      return "a";
    }
    return "button";
  });

  const parsedRel = computed(() => {
    if (
      !props.href ||
      props.href.startsWith("/") ||
      props.href.includes(window.location.hostname)
    ) {
      return props.rel;
    }

    if (!props.rel) {
      return "noopener noreferrer";
    }

    return unique([...props.rel.split(" "), "noopener", "noreferrer"]).join(
      " ",
    );
  });

  const buttonProps = computed<ButtonPropsA | ButtonPropsButton>(() => {
    if (tag.value === "a") {
      return {
        href: props.href ?? "",
        target: props.target,
        rel: parsedRel.value,
      };
    }

    return {
      type: props.type,
      disabled: isDisabled.value,
    };
  });

  const click = (event: PointerEvent) => emit("click", event);

  return {
    tag,
    buttonProps,
    actionType: toRef(() => props.actionType),
    color: toRef(() => props.color),
    size: toRef(() => props.size),
    prefixIcon: internalPrefixIcon,
    suffixIcon: internalSuffixIcon,
    disabled: isDisabled,
    click,
  };
}

export type { UseButtonEmit, UseButtonProps };
export { emits, props, use };
