<template>
  <div class="form-question">
    <SEmail
      v-model="email"
      :label="tr('forms.fields.email.label')"
      name="email"
      required
      show-required-type="none"
      :typo-correction="localeMorpheus"
      v-on="reEmit(emit, ['focus', 'validationError'])"
    />
    <SCheckbox
      v-model="newsletter"
      :label="tr('forms.fields.newsletter.label')"
      name="newsletter"
      v-on="reEmit(emit, ['focus', 'validationError'])"
    />
  </div>
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";
import { toRef } from "@vueuse/core";

import { SCheckbox } from "@solvari/common-fe";
import { SEmail } from "@solvari/components";

import { useI18nForms } from "@/plugins/i18n.ts";
import { useLeadStore } from "@/plugins/store/lead";

const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const { localeMorpheus, tr } = useI18nForms();

const leadStore = useLeadStore();
const email = toRef(leadStore, "email");
const newsletter = toRef(leadStore, "newsletter");
</script>
