import type { Color } from "@solvari/common-fe";

function toLegacySize(size: "large" | "small" | null | undefined) {
  return size ? ({ small: "sm", large: "lg" } as const)[size] : ("md" as const);
}

function toSeverity(color: Color) {
  return (
    {
      danger: "error",
      warning: "warn",
      primary: "info",
      success: "success",
      accent: "success",
      gray: "secondary",
    } as const
  )[color];
}

export { toLegacySize, toSeverity };
