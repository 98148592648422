<template>
  <Component
    :is="fieldset ? 'fieldset' : 'div'"
    :class="[$style.container, { 's-input--invalid': invalid }]"
  >
    <div v-if="label || tooltip" :class="$style.labelContainer">
      <SLabel
        v-if="label"
        :disabled
        :for="!fieldset ? id : undefined"
        :pt="pt?.pcLabel"
        :required
        :show-required-type
        :size
        :tag="fieldset ? 'legend' : undefined"
      >
        {{ label }}
      </SLabel>
      <STooltipInfo
        v-if="tooltip"
        :id="ids.tooltip.value"
        :class="$style.tooltip"
        :tooltip="tooltip"
      />
    </div>
    <PMessage
      v-if="description"
      icon="fa-regular fa-circle-info"
      :pt="
        assign({ root: { id: ids.description.value } }, pt?.pcDescription ?? {})
      "
      :size="size"
    >
      {{ description }}
    </PMessage>
    <PIconField>
      <PInputIcon
        v-if="prefixIcon"
        :class="prefixIcon"
        :pt="pt?.pcPrefixIcon"
        :size
      />
      <slot
        :id="id"
        :described-by="describedBy"
        :error-id="errorId"
        :invalid="invalid"
        :listeners="validationListeners"
        name="input"
      />
      <PInputIcon
        v-if="suffixIcon"
        :class="suffixIcon"
        :pt="pt?.pcSuffixIcon"
        :size
      />
    </PIconField>
    <PMessage
      v-if="subtext"
      :pt="assign({ root: { id: ids.subtext.value } }, pt?.pcSubtext ?? {})"
      severity="gray"
      size="small"
      variant="simple"
    >
      {{ subtext }}
    </PMessage>
    <slot
      v-if="errorId"
      :id="errorId"
      :name="errorComponent!"
      v-bind="errorProps"
    >
      <PMessage
        :id="errorId"
        :pt="pt?.pcError"
        :severity="errorProps.color ? toSeverity(errorProps.color) : 'error'"
        size="small"
        variant="simple"
      >
        {{ error }}
      </PMessage>
    </slot>
  </Component>
</template>

<script setup lang="ts" generic="ModelValue">
import { useValidationProvider } from "@solvari/common-fe/validation";
import { nanoid } from "nanoid";
import { assign } from "radash";
import { toRef } from "vue";

import { STooltipInfo } from "@solvari/common-fe";

import type {
  BaseInputEmits,
  BaseInputPassThroughOptions,
  BaseInputProps,
} from "@/molecules/baseInput.ts";

import SLabel from "@/atoms/SLabel.vue";
import { toSeverity } from "@/helpers/backwardsCompatibility.ts";
import { useDescribedBy } from "@/helpers/useDescribedBy.ts";
import { useSuffixIcon } from "@/helpers/useSuffixIcon.ts";
import { PIconField, PInputIcon, PMessage } from "@/primeVueExports.ts";

const props = withDefaults(
  defineProps<
    BaseInputProps<ModelValue> & {
      fieldset?: boolean;
      pt?: BaseInputPassThroughOptions;
    }
  >(),
  {
    showValidState: true,
    rules: () => [],
    fieldset: false,
  },
);

const emit = defineEmits<BaseInputEmits>();

const id = nanoid(10);

const { describedBy, ids } = useDescribedBy({
  tooltip: () => props.tooltip,
  description: () => props.description,
  subtext: () => props.subtext,
});

const {
  validationListeners,
  valid,
  invalid,
  error,
  errorId,
  errorProps,
  errorComponent,
  validating,
} = useValidationProvider(
  toRef(() => props.modelValue),
  props,
  emit,
);

const suffixIcon = useSuffixIcon({
  loading: () => props.loading,
  valid,
  invalid,
  validating,
  suffixIcon: () => props.suffixIcon,
  showValidState: () => props.showValidState,
});
</script>

<style module lang="postcss">
.container {
  @apply flex flex-col gap-2;
}

.labelContainer {
  @apply flex;
}

.tooltip {
  @apply ml-2;
}
</style>
