/* eslint-disable @typescript-eslint/naming-convention */

const localeIsoEnum = Object.freeze({
  "da-DK": "da-DK",
  "fr-BE": "fr-BE",
  "fr-FR": "fr-FR",
  "nl-BE": "nl-BE",
  "nl-NL": "nl-NL",
  "sv-SE": "sv-SE",
} as const);
type LocaleIso = (typeof localeIsoEnum)[keyof typeof localeIsoEnum];

/** Our own locale format, it reverses the ISO locale format */
const localeMorpheusEnum = Object.freeze({
  "be-FR": "be-FR",
  "be-NL": "be-NL",
  "dk-DA": "dk-DA",
  "fr-FR": "fr-FR",
  "nl-NL": "nl-NL",
  "se-SV": "se-SV",
} as const);
type LocaleMorpheus =
  (typeof localeMorpheusEnum)[keyof typeof localeMorpheusEnum];

const localeCountryEnum = Object.freeze({
  BE: "BE",
  DK: "DK",
  FR: "FR",
  NL: "NL",
  SE: "SE",
} as const);
type LocaleCountry = (typeof localeCountryEnum)[keyof typeof localeCountryEnum];

const localeLanguageEnum = Object.freeze({
  da: "da",
  fr: "fr",
  nl: "nl",
  sv: "sv",
} as const);
type LocaleLanguage =
  (typeof localeLanguageEnum)[keyof typeof localeLanguageEnum];

type LocaleDocument = LocaleIso | LocaleLanguage;

type LocaleAny =
  | LocaleDocument
  | LocaleIso
  | LocaleMorpheus
  | Lowercase<LocaleDocument | LocaleIso | LocaleMorpheus>
  | Uppercase<LocaleDocument | LocaleIso | LocaleMorpheus>;

type LocaleFormats = {
  country: LocaleCountry;
  document: LocaleDocument;
  iso: LocaleIso;
  language: LocaleLanguage;
  morpheus: LocaleMorpheus;
};

const localeFormatsMap = Object.freeze([
  {
    morpheus: "nl-NL",
    iso: "nl-NL",
    language: "nl",
    country: "NL",
    document: "nl",
  },
  {
    morpheus: "be-NL",
    iso: "nl-BE",
    language: "nl",
    country: "BE",
    document: "nl-BE",
  },
  {
    iso: "fr-BE",
    document: "fr-BE",
    language: "fr",
    country: "BE",
    morpheus: "be-FR",
  },
  {
    morpheus: "fr-FR",
    iso: "fr-FR",
    language: "fr",
    country: "FR",
    document: "fr",
  },
  {
    morpheus: "dk-DA",
    iso: "da-DK",
    language: "da",
    country: "DK",
    document: "da",
  },
  {
    morpheus: "se-SV",
    iso: "sv-SE",
    language: "sv",
    country: "SE",
    document: "sv",
  },
] as const satisfies LocaleFormats[]);
type LocaleMap = (typeof localeFormatsMap)[number];

function localeToMap(locale: LocaleAny): LocaleFormats {
  return localeFormatsMap.find(({ iso, document, morpheus }) => {
    const lowerCaseLocale = locale.toLowerCase();
    return (
      iso.toLowerCase() === lowerCaseLocale ||
      document.toLowerCase() === lowerCaseLocale ||
      morpheus.toLowerCase() === lowerCaseLocale
    );
  })!;
}

const localeToIso = (locale: LocaleAny) => localeToMap(locale).iso;
const localeToMorpheus = (locale: LocaleAny) => localeToMap(locale).morpheus;
const localeToCountry = (locale: LocaleAny) => localeToMap(locale).country;
const localeToLanguage = (locale: LocaleAny) => localeToMap(locale).language;

function countryToMaps(country: LocaleCountry) {
  return localeFormatsMap.filter((item) => item.country === country);
}

function isLocaleMorpheus(locale: string): locale is LocaleMorpheus {
  return locale in localeMorpheusEnum;
}
function isLocaleIso(locale: string): locale is LocaleIso {
  return locale in localeIsoEnum;
}

export {
  countryToMaps,
  isLocaleIso,
  isLocaleMorpheus,
  localeCountryEnum,
  localeIsoEnum,
  localeLanguageEnum,
  localeMorpheusEnum,
  localeToCountry,
  localeToIso,
  localeToLanguage,
  localeToMap,
  localeToMorpheus,
};

export type {
  LocaleCountry,
  LocaleIso,
  LocaleLanguage,
  LocaleMap,
  LocaleMorpheus,
};
