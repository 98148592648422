/*
  The service handles backend communication and initializing and continuing the session.
  This should only run once on the page.
 */

import { v4 as createUuid } from "uuid";

import { singleton } from "@solvari/utils";

import { initRootClient } from "@/lib/argus/client/rootClient";
import {
  applyArgusUuidToUrl,
  argusSession,
  getArgusEvents,
} from "@/lib/argus/client/thinClient";
import { setCookie } from "@/lib/helpers/cookies";
import { isBot, isSupportedBrowser } from "@/lib/helpers/isSupportedBrowser.ts";
import { sSessionStorage } from "@/lib/helpers/localStorage";

const crossDomainSession = singleton(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const referrerUrlParams = document.referrer
    ? new URLSearchParams(new URL(document.referrer).search)
    : null;

  const session = {
    sessionUuid:
      urlParams.get("solvariSession") ??
      referrerUrlParams?.get("solvariSession") ??
      null,
    clientId:
      urlParams.get("solvariClientId") ??
      referrerUrlParams?.get("solvariClientId") ??
      null,
  };

  urlParams.delete("solvariClientId");
  urlParams.delete("solvariSession");

  return session;
});

async function initArgus() {
  if (isBot() || !isSupportedBrowser()) {
    return;
  }

  storeSolvariClientId(
    crossDomainSession().clientId ?? argusSession().clientId ?? createUuid(),
  );

  const sessionType = getSessionType();
  if (sessionType === "new") {
    // If no events are present there should not be a local session
    sSessionStorage.removeItem("solvariSession");
  }
  if (sessionType === "cross-domain") {
    storeSolvariSessionUuid(crossDomainSession().sessionUuid!);
  }

  initCrossDomainLinker();

  const sessionUuid = await initRootClient(sessionType).flush();
  if (sessionUuid && !(sessionUuid instanceof Error)) {
    storeSolvariSessionUuid(sessionUuid);
  }
}

type SessionType = "cross-domain" | "new" | "same-domain";
function getSessionType(): SessionType {
  if (getArgusEvents()?.length) {
    return "same-domain";
  }

  if (crossDomainSession().sessionUuid) {
    return "cross-domain";
  }

  return "new";
}

function storeSolvariClientId(uuid: string) {
  setCookie({ name: "solvariClientId", value: uuid, maxAge: "31536000" });
}

function storeSolvariSessionUuid(uuid: string) {
  sSessionStorage.setItem("solvariSession", uuid);
}

function initCrossDomainLinker() {
  document.addEventListener("click", (event) => {
    const anchorElement = (
      event.target as HTMLElement
    ).closest<HTMLAnchorElement>("a[href]");

    if (!anchorElement) {
      return;
    }

    anchorElement.href = applyArgusUuidToUrl(anchorElement.href);
  });
}

export {
  getSessionType,
  initArgus,
  storeSolvariClientId,
  storeSolvariSessionUuid,
};
export type { SessionType };
