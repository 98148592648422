import ky from "ky";
import { shake } from "radash";

import type { LocaleIso } from "@solvari/translations";
import { getEnv, memoize } from "@solvari/utils";

import { withKyErrors } from "@/lib/integrations.ts";
import { zipcodeRegex } from "@/lib/validation.ts";

const getZipcodeCitySuggestions = withKyErrors(
  memoize(
    async ({
      zipcode,
      city,
      locale,
    }: {
      city: string | null;
      locale: Extract<LocaleIso, "da-DK" | "fr-BE" | "nl-BE">;
      zipcode: string | null;
    }): Promise<{ city: string; zipcode: string }[]> => {
      // This should match BE validation
      if (
        (zipcode && !zipcodeRegex[`${locale}_partial`].test(zipcode)) ||
        (!zipcode && !city?.trim())
      ) {
        return [];
      }

      const response = await ky
        .get(`${getEnv().network.api}/system/v3/geo/suggest/city/be`, {
          searchParams: shake({ zipcode, city, locale }),
        })
        .json<{ data: { city: string; zipcode: string }[] }>();
      return response.data;
    },
  ),
  { timeoutError: false },
);

const getCitySuggestions = withKyErrors(
  memoize(
    async ({
      locale,
      city,
    }: {
      city: string | null;
      locale: LocaleIso;
    }): Promise<string[]> => {
      // This should match BE validation
      if (!city?.trim()) {
        return [];
      }

      const response = await ky
        .get(`${getEnv().network.api}/system/v3/geo/suggest/city`, {
          searchParams: { locale, city },
        })
        .json<{ data: string[] }>();
      return response.data;
    },
  ),
  { timeoutError: false },
);

async function getStreetBeSuggestions({
  locale,
  zipcode,
  street,
}: {
  locale: LocaleIso;
  street: string | null;
  zipcode: string | null;
}): Promise<string[]> {
  // This should match BE validation
  if (!street?.trim() || !(zipcode && zipcodeRegex[locale].test(zipcode))) {
    return [];
  }

  const response = await ky
    .get(`${getEnv().network.api}/system/v3/geo/suggest/street/be`, {
      searchParams: { locale, zipcode, street },
    })
    .json<{ data: string[] }>();

  return response.data;
}

async function getStreetNlSuggestions({
  locale,
  city,
  street,
}: {
  city: string | null;
  locale: Extract<LocaleIso, "nl-NL">;
  street: string | null;
}): Promise<string[]> {
  // This should match BE validation
  if (!street?.trim()) {
    return [];
  }

  const response = await ky
    .get(`${getEnv().network.api}/system/v3/geo/suggest/street`, {
      searchParams: shake({ locale, city, street }),
    })
    .json<{ data: string[] }>();

  return response.data;
}

const getStreetSuggestions = withKyErrors(
  memoize(
    async (
      params:
        | {
            city: string | null;
            locale: Extract<LocaleIso, "nl-NL">;
            street: string | null;
          }
        | {
            locale: Extract<LocaleIso, "da-DK" | "fr-BE" | "nl-BE">;
            street: string | null;
            zipcode: string | null;
          },
    ) => {
      if (params.locale === "nl-NL") {
        return getStreetNlSuggestions(params);
      }
      return getStreetBeSuggestions(params);
    },
  ),
  { timeoutError: false },
);

const getHouseNumberSuggestions = withKyErrors(
  memoize(
    async ({
      locale,
      zipcode,
      houseNumber,
    }: {
      houseNumber: string | null;
      locale: LocaleIso;
      zipcode: string | null;
    }): Promise<string[]> => {
      // This should match BE validation
      if (
        !(zipcode && zipcodeRegex[locale].test(zipcode)) ||
        !houseNumber?.trim()
      ) {
        return [];
      }

      const response = await ky
        .get(`${getEnv().network.api}/system/v3/geo/suggest/housenumbers`, {
          searchParams: { locale, zipcode, housenumber: houseNumber },
        })
        .json<{ data: string[] }>();
      return response.data;
    },
  ),
  { timeoutError: false },
);

type AutocompleteResult = {
  city: string;
  street: string;
  zipcode: string;
};

const autocompleteNlNlAddress = withKyErrors(
  memoize(async ({ zipcode }: { zipcode: string }) => {
    // This should match BE validation
    if (!zipcodeRegex["nl-NL"].test(zipcode)) {
      return null;
    }

    const response = await ky
      .get(`${getEnv().network.api}/system/v3/geo/autocomplete`, {
        searchParams: { zipcode },
      })
      .json<AutocompleteResult | Record<string, never>>();
    if (!Object.keys(response).length) {
      return null;
    }
    return response as AutocompleteResult;
  }),
  { timeoutError: false },
);

export {
  autocompleteNlNlAddress,
  getCitySuggestions,
  getHouseNumberSuggestions,
  getStreetSuggestions,
  getZipcodeCitySuggestions,
};
