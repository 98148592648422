import type { App } from "vue";

import PrimeVue from "primevue/config";

import solvariPreset from "./themes/solvariPreset";

const primeVueThemedPlugin = [
  PrimeVue,
  {
    theme: {
      preset: solvariPreset,
      options: {
        darkModeSelector: "none",
      },
    },
  },
] as const;
function initPrimeVueThemed(app: unknown) {
  const vueApp = app as App;
  vueApp.use(...primeVueThemedPlugin);
}

export { initPrimeVueThemed, primeVueThemedPlugin };
