<template>
  <SAlert
    v-if="description"
    v-bind="containerProps"
    class="s-description"
    color="primary"
    prefix-icon="fa-regular fa-circle-info"
  >
    {{ description }}
  </SAlert>
</template>

<script setup lang="ts">
import SAlert from "@/lib/components/atoms/alert/SAlert.vue";
import * as useDescription from "@/lib/components/logic/atoms/useDescription";

const props = defineProps(useDescription.props);
const { containerProps, description } = useDescription.use(props);
</script>

<style lang="postcss">
.s-description {
  @apply mb-2;
}
</style>
