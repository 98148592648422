import type { Ref } from "vue";

import { reactive } from "vue";

import { useGtmStore } from "@/plugins/store/gtm";

export function useGtmListeners(label: Ref<string>) {
  const gtmStore = useGtmStore();

  const previousError: Record<string, string | null> = reactive({});

  function onFocus(name: string) {
    void gtmStore.formInteractionEvent({
      name,
      label: label.value,
    });
  }

  function onValidationError({ error, name }: { error: string; name: string }) {
    if (previousError.value === error) {
      return;
    }

    previousError.value = error;

    if (error) {
      void gtmStore.formErrorEvent({
        name,
        label: label.value,
        error,
      });
    }
  }

  return {
    focus: onFocus,
    validationError: onValidationError,
  };
}
