import type { PropType } from "vue";

import { reactive, toRef, watch } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { size } from "@/lib/components/logic/atoms/input/props";
import { useTemplateRef } from "@/lib/composables";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useElementValue } from "@/lib/composables/useElementValue";

const props = propsDefinition({
  id: { type: String, required: false },
  value: { type: [Boolean, String, Number], required: true },
  size,
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  modelValue: { type: Boolean, default: false },
  indeterminate: { type: Boolean, default: false },
  type: { type: String as PropType<"checkbox" | "radio">, default: "radio" },
  isActive: { type: Boolean, default: false },
  label: { type: String, default: "" },
  name: { type: String, required: true },
});

const emits = emitsDefinition(["click"]);

type UseOptionProps = DefineProps<typeof props>;
type UseOptionEmit = (event: "click", modelValue: boolean) => void;

function use(props: UseOptionProps, emit: UseOptionEmit) {
  const { el, ref } = useTemplateRef();

  function onClick() {
    if (!props.disabled) {
      emit("click", props.type === "checkbox" ? !props.modelValue : true);
    }
  }

  watch(
    toRef(() => props.isActive),
    (active) => {
      if (!active || !el.value) {
        return;
      }
      if (
        "scrollIntoViewIfNeeded" in el.value &&
        typeof el.value.scrollIntoViewIfNeeded === "function"
      ) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        el.value.scrollIntoViewIfNeeded(false);
      } else {
        el.value.scrollIntoView(false);
      }
    },
  );

  const input = {
    if: toRef(() => props.type === "checkbox"),
    props: reactive({
      tabindex: "-1",
      "aria-hidden": true,
      value: toRef(() => props.value),
      modelValue: toRef(() => props.modelValue),
      disabled: toRef(() => props.disabled),
      indeterminate: toRef(() => props.indeterminate),
      name: toRef(() => props.name),
    }),
  };

  const option = {
    ref,
    props: reactive({
      role: "option",
      id: toRef(() => props.id),
      "aria-selected": toRef(() => props.modelValue),
      "aria-disabled": toRef(() => props.disabled),
      value: useElementValue(toRef(() => props.value)),
    }),
    on: {
      click: onClick,
    },
  };

  return {
    input,
    option,
    label: toRef(() => props.label),
    disabled: toRef(() => props.disabled),
    modelValue: toRef(() => props.modelValue),
    isActive: toRef(() => props.isActive),
    size: toRef(() => props.size),
  };
}

export type { UseOptionEmit, UseOptionProps };
export { emits, props, use };
