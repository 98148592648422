import { toReactive } from "@vueuse/core";
import { computed, reactive, toRef } from "vue";

import type { PrimitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";
import type { UseCheckboxRadioGroupEmit } from "@/lib/components/logic/atoms/useCheckboxRadioGroup";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import * as useCheckboxRadioGroup from "@/lib/components/logic/atoms/useCheckboxRadioGroup";
import * as useDescription from "@/lib/components/logic/atoms/useDescription";
import * as useLabel from "@/lib/components/logic/atoms/useLabel";
import * as useSubtext from "@/lib/components/logic/atoms/useSubtext";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";
import { useDescribedBy } from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useLabelFallback } from "@/lib/composables/useLabelFallback.ts";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useLabel.scoped,
  ...useTooltip.scoped,
  ...useDescription.scoped,
  ...useValidationProviderScoped<PrimitiveOrArrayValue>(),
  ...useCheckboxRadioGroup.scoped,
  ...useSubtext.scoped,
});

const emits = emitsDefinition([
  ...useCheckboxRadioGroup.emits,
  ...useValidationProviderEmits,
]);

type UseButtonGroupProps = DefineProps<typeof props>;
type UseButtonGroupEmit = UseCheckboxRadioGroupEmit &
  UseValidationProviderEmits;

function use(props: UseButtonGroupProps, emit: UseButtonGroupEmit) {
  const { validationListeners, error, errorComponent, errorProps } =
    useValidationProvider(() => props.modelValue, props, emit);

  const { label } = useLabelFallback(
    toRef(() => props.name),
    reactivePick(props, ["label"]),
  );

  const { describedBy, ids } = useDescribedBy(
    reactivePick(props, ["tooltip", "description", "subtext"]),
  );

  const container = {
    props: reactive({ "aria-describedby": describedBy }),
  };

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabel.scoped), {
      tag: "legend",
      label,
    }),
  };

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltip.scoped), {
      tooltipId: toRef(() => ids.tooltip),
    }),
  };

  const descriptionAtom = {
    if: computed(() => !!props.description),
    props: mergeReactive(pickProps(props, useDescription.scoped), {
      descriptionId: toRef(() => ids.description),
    }),
  };

  const checkboxRadioGroup = {
    props: pickProps(props, useCheckboxRadioGroup.scoped),
    on: mergeListeners(
      reEmit(emit, useCheckboxRadioGroup.emits),
      toReactive(validationListeners),
    ),
  };

  const subtextAtom = {
    if: computed(() => !!props.subtext),
    props: mergeReactive(pickProps(props, useSubtext.scoped), {
      subtextId: toRef(() => ids.subtext),
    }),
  };

  return {
    container,
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    checkboxRadioGroup,
    subtextAtom,
    error,
    errorComponent,
    errorProps,
  };
}

export type { UseButtonGroupEmit, UseButtonGroupProps };
export { emits, props, use };
