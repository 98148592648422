import type { ObjectToSnake } from "ts-case-convert/lib/caseConvert";

import { getSentry, ziggy } from "@solvari/common-fe/integrations";
import ky from "ky";

import {
  type LocaleIso,
  localeToCountry,
  localeToLanguage,
} from "@solvari/translations";

async function getAddressSuggestions({
  locale,
  query,
  sessionId,
  types = "address,street",
}: {
  locale: LocaleIso;
  query: string;
  sessionId: string;
  types?: string;
}): Promise<MapBoxAutocompleteItem[]> {
  const countryCode = localeToCountry(locale).toLowerCase();
  const lang = localeToLanguage(locale).toLowerCase();
  const url = ziggy("api.geocoder.mapbox_autocomplete");

  try {
    const { suggestions } = await ky
      .get(url, {
        searchParams: {
          // eslint-disable-next-line id-length
          q: query,
          language: lang,
          country: countryCode,
          types,
          session_token: sessionId,
        },
      })
      .json<ObjectToSnake<MapboxResponse>>();

    return suggestions.map(
      ({ context, name, place_formatted: placeFormatted }) => {
        return {
          label: `${name}, ${placeFormatted}`,
          city: context.place.name,
          houseNumber: context.address?.address_number || null,
          street: context.street.name,
          zipcode: context.postcode.name,
        };
      },
    ) as MapBoxAutocompleteItem[];
  } catch (error) {
    getSentry().captureException(error);

    return [] as MapBoxAutocompleteItem[];
  }
}

export { getAddressSuggestions };

//  https://docs.mapbox.com/api/search/search-box/
type MapboxResponse = {
  suggestions: MapboxSuggestion[];
};

type MapboxSuggestion = {
  context: {
    address?: {
      addressNumber: string;
    };
    country: {
      name: string;
    };
    place: {
      name: string;
    };
    postcode: {
      name: string;
    };
    street: {
      name: string;
    };
  };
  name: string;
  placeFormatted: string;
};

export type MapBoxAutocompleteItem = {
  city: string;
  houseNumber: string | null;
  label: string;
  street: string;
  zipcode: string;
};
