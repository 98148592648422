/*
  Clients only need to get feature values. This avoids including the entire ab-testing dependency.
 */

import { getSentry } from "@/lib/integrations/sentry/client.ts";

function captureAbTestingException(error: unknown) {
  getSentry().captureException(error, { tags: { project: "mkt-ab-testing" } });
}

function getAbTestFeature<Type>(key: string) {
  return window.abTesting?.getFeatureValue<Type | null>(key, null) ?? null;
}

export { captureAbTestingException, getAbTestFeature };
